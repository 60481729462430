import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import type {
  CardVariant,
  CategoryLinksType,
  CustomCardsType,
  ColumnsStructureType,
  ProductCardsType,
  TTDBlogPostLinksType,
} from '../../types/slices';
import type { PrismicContext } from '../../types/context';
import { CardVariantsFactory } from '../../utils/CardVariantsFactory-v2';

import ColumnStructureWrapper from './ColumnStructureWrapper';

const ColumnStructure = ({
  slice,
  context,
}: {
  slice: ColumnsStructureType;
  context: PrismicContext;
}) => {
  const { primary, items, variation, position } = slice;
  const { title, title_level, description, collapse_below } = primary;
  const { hide, isFaceted, isProductCardsV2 } = context;
  const optionsObj = {
    variation,
    primary,
    items,
  };

  if (isFaceted || hide) {
    return null;
  }

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <CardVariantsFactory
        Wrapper={ColumnStructureWrapper}
        wrapperProps={{
          title,
          title_level,
          description,
          collapse_below,
        }}
        options={typeOptions(optionsObj, variation)}
        priority={priority}
        isProductCardsV2={isProductCardsV2}
        isProductCardWithImageCarousel
      />
    </Container>
  );
};

const typeOptions = (obj: any, variation: CardVariant) => {
  switch (variation) {
    case 'cardsProductIDs':
      return obj as ProductCardsType;
    case 'cardsCategoryLinks':
      return obj as CategoryLinksType;
    case 'cardsPostLinks':
      return obj as TTDBlogPostLinksType;
    case 'cardsCustomContent':
      return obj as CustomCardsType;
  }
};

export default ColumnStructure;
