import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { PromoBanner as Component } from '@virginexperiencedays/components-v2/src/banners/Promo';

import {
  PrismicLink,
  getPrismicLinkPath,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import type { PrismicContext } from '../../types/context';

type BannerProps = {
  slice: {
    variation: 'default' | 'htmlEmbed';
    primary: {
      title: string;
      body: { text: string } | string;
      cta_text: string;
      url?: PrismicLink;
      image: { url: string; alt?: string };
      mobile_image?: { url: string; alt?: string };
      prefetch_image?: boolean;
    };
    slice_type: string;
    position?: number;
  };
  context: PrismicContext;
};

const PromoBanner = ({ slice, context }: BannerProps) => {
  const { primary, variation, slice_type, position } = slice;

  const { title, body, cta_text, url, image, mobile_image, prefetch_image } = primary;
  const { lastSlug, pageType, isFaceted, trackSlice } = context;

  const htmlOverride = variation === 'htmlEmbed' ? (body as string) : null;

  const trackCtaLink = () => {
    // let original event propagate

    if (url) return null;
    trackSlice?.({
      slug: lastSlug,
      label: getPrismicLinkPath(url),
      sliceType: slice_type,
      pageType: pageType,
    });
  };

  if (isFaceted) return null;

  // This is used to determine whether images should be prioritised for LCP preloading
  const priority = prefetch_image || position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <Component
        variation={variation}
        title={title}
        body={body}
        ctaText={cta_text}
        url={url ? getPrismicLinkPath(url) : null}
        image={image}
        mobileImage={mobile_image}
        prefetchImage={priority}
        htmlOverride={htmlOverride}
        onClick={trackCtaLink}
      />
    </Container>
  );
};

export default PromoBanner;
