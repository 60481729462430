import { useRef } from 'react';

import {
  PrismicLink,
  getPrismicLinkPath,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { CarouselWithTabs as Component } from '@virginexperiencedays/components-v2/src/carousels/CarouselWithTabs';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { Track } from '@virginexperiencedays/components-v2/src/layout/Carousel/Track';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import { CardVariantsFactory, typeOptions } from '../../utils/CardVariantsFactory-v2';
import { getContentLinkTitle, stripExperiencesText } from '../../utils/overrides';

import type { PrismicContext } from '../../types/context';
import type { CarouselWithTabsType } from '../../types/slices';

type CarouselWithTabsProps = {
  slice: CarouselWithTabsType;
  context: PrismicContext;
};

const CarouselWithTabs = ({ slice, context }: CarouselWithTabsProps) => {
  const { primary, items: categoryItems, variation, slice_type, position } = slice;
  const { isFaceted, pageType, lastSlug, trackSlice, isProductCardsV2 } = context;

  const items = categoryItems.filter((data) => !!data?.items?.length);

  const carouselRef = useRef(items.map(() => null));
  const firstChildRef = useRef(items.map(() => null));
  const lastChildRef = useRef(items.map(() => null));

  if (isFaceted || !items?.length) return null;

  const tabs: { id: string; title: string; imageUrl: string; imageAlt: string }[] = [];
  for (const item of items) {
    const slice = item.category_link?.uid ? item.category_link : item?.curated_tab_link;
    if (!slice) continue;
    tabs.push({
      id: slice.id,
      title: item.tab_title_override ?? stripExperiencesText(getContentLinkTitle(slice)),
      imageUrl: item.hero_image?.url,
      imageAlt: item.hero_image?.alt,
    });
  }

  // required as Primsic's initial default value is always null
  const heroImageIsPriority =
    primary.hero_image_above_fold === null || !!primary.hero_image_above_fold;

  const hasHeroLogo = !!primary.hero_logo && primary.hero_logo !== 'None';
  const isRegularHeroBanner =
    primary.hero_logo === 'None' &&
    tabs.filter(({ imageUrl }) => !!imageUrl).length === tabs.length;

  const onTabClick = (tabName: string) => {
    // tracking
    trackSlice?.({
      slug: lastSlug,
      label: tabName,
      sliceType: slice_type,
      pageType,
    });
  };

  const endCardTrackCtaLink = (ctaLink: PrismicLink) => {
    if (ctaLink) {
      trackSlice?.({
        pageType,
        slug: lastSlug,
        label: getPrismicLinkPath(ctaLink),
        sliceType: 'carousel_end_card_cta',
      });
    }
  };

  const endCardTrackOnReached = (title: string) => {
    if (title) {
      trackSlice?.({
        pageType,
        slug: lastSlug,
        label: title,
        sliceType: 'carousel_end_card_cta',
        action: 'Reached end card',
      });
    }
  };

  const carouselProps = {
    tabs,
    carouselRef,
    firstChildRef,
    lastChildRef,
    title: primary.title,
    description: primary.description,
    ctaTitle: primary.cta_title,
    href: primary.cta_href && getPrismicLinkPath(primary.cta_href),
    isHrefExternal: true,
    showNavigationArrows: primary.desktop_show_navigation_arrows,
    variant: primary.hero_logo,
    heroImageIsPriority,
    // show hero banners if a hero logo option is selected and it is not "None", or
    // if it is "None", it should have all tabs uploaded with hero image
    withHero: hasHeroLogo || isRegularHeroBanner,
    onTabClick,
  };

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <Component {...carouselProps}>
        {items.map((relation, relationIndex) => {
          const optionsObj = {
            variation: relation?.curated_tab_link?.id ? 'cardsProductIDs' : variation,
            items: relation.items,
            primary: {
              ...primary,
              /**
               * NOTE: Insert a default card title of h3 here as opposed to
               * CardVariantsFactory's default of h2, because CarouselStructure has its
               * own title instead of rendering each card directly.
               */
              card_title_as: 'h3',
            },
          };
          const endCardData = {
            displayEndCard: relation?.display_end_card,
            endCardTitle: relation?.end_card_title,
            endCardOverTitle: relation?.end_card_over_title,
            endCardCtaTitle: relation?.end_card_cta_title,
            endCardCtaLink:
              relation?.end_card_cta_link && getPrismicLinkPath(relation?.end_card_cta_link),
            endCardTrackCtaLink: () => endCardTrackCtaLink(relation?.end_card_cta_link),
            endCardTrackOnReached: () => endCardTrackOnReached(relation?.end_card_title),
          };

          const key = tabs?.[relationIndex]?.title ?? relationIndex;
          const wrapperProps = {
            carouselRef: (el: Element) => (carouselRef.current[relationIndex] = el),
            firstChildRef: (el: Element) => (firstChildRef.current[relationIndex] = el),
            lastChildRef: (el: Element) => (lastChildRef.current[relationIndex] = el),
            perPage: {
              tablet: 3,
              desktop: 4,
              wide: 5,
            },
            isProductCarousel: true,
          };

          return (
            <CardVariantsFactory
              key={`tabbed-carousel-cards-${key}`}
              Wrapper={Track}
              wrapperProps={wrapperProps}
              options={typeOptions(optionsObj, variation)}
              priority={priority && relationIndex === 0}
              endCardData={endCardData}
              isProductCardsV2={isProductCardsV2}
            />
          );
        })}
      </Component>
    </Container>
  );
};

export default CarouselWithTabs;
