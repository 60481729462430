// Utils
import { slugToName } from '@virginexperiencedays/search/utils/category';

import type { HierarchicalFacet, RefinementListItem } from '../types';
import { categoryPrefixes } from '../constants';

type ItemToNavItemOpts = {
  categoryPrefix: categoryPrefixes;
};

export const itemToNavItem = (item: HierarchicalFacet[], opts?: ItemToNavItemOpts) => {
  if (!item) return [];

  const { categoryPrefix } = opts || {};

  const navigationItems = item.map(({ name, path }) => {
    const prefixedPath = [categoryPrefix, path].filter((x) => x).join('/');

    return {
      name: slugToName(name),
      // use absolute hrefs (starting with a leading slash)
      href: prefixedPath && `/${prefixedPath}`,
    };
  });

  return navigationItems;
};

export const itemToNavItemNew = (items: RefinementListItem[]) => {
  return items.map(({ label, value: fullSlug }) => {
    const normalizedLabel = label.split('/').pop();
    const value = fullSlug.split('/').pop();

    return {
      name: slugToName(normalizedLabel),
      href: `/${value}`,
    };
  });
};
