import { cn } from '@virginexperiencedays/tailwind';

import { BodyText } from '../../typography/BodyText';
import { DisplayHeading } from '../../typography/DisplayHeading';
import { Carousel } from '../../layout/Carousel';

import { DisplayCard, DisplayCardProps } from '../Display/v2';

type FeaturedVisualCardsProps = {
  className?: string;
  dataTestId?: string;
  title?: string;
  description?: string;
  items?: DisplayCardProps[];
  limit?: 3; // there is no other use case for now, therefore its hard-coded but in the future we should limit range and generate classnames before hand for each value
  priority?: boolean;
  priorityCount?: number;
};

export const FeaturedVisualCards = ({
  className,
  title,
  description,
  items,
  dataTestId = 'featured-visual-cards',
  limit = 3,
  priority = false,
  priorityCount = null,
}: FeaturedVisualCardsProps) => (
  <div data-testid={dataTestId} className={className}>
    {!!title && (
      <DisplayHeading
        data-testid="featured-visual-cards-intro-title"
        size="2"
        className="text-neutral-strong mb-2 text-center leading-tight"
      >
        {title}
      </DisplayHeading>
    )}
    {!!description && (
      <BodyText
        className="text-neutral mx-auto mb-6 max-w-[800px] text-center text-base leading-normal"
        data-testid="featured-visual-cards-intro-description"
      >
        {description}
      </BodyText>
    )}
    {!!items?.length && (
      <Carousel
        className={cn(
          'mx-auto my-0 max-w-[960px] [&>ul>li]:w-[310px] [&>ul>li]:max-w-[calc(100%_-_80px)]',
          'md:[&>ul>li]:max-w-[calc((100%_-_16_*_2px)_/3)]'
        )}
        headingClassName="mb-0"
        showNavigationArrows={false}
      >
        {items
          .slice(0, limit)
          .map(({ title, overTitle, imageUrl, ctaText, ctaHref, onClick }, index) => (
            <DisplayCard
              key={`featured-visual-cards-item-${title}`}
              overTitle={overTitle}
              title={title}
              imageUrl={imageUrl}
              ctaHref={ctaHref}
              ctaText={ctaText}
              onClick={onClick}
              priority={priority && priorityCount !== null && index <= priorityCount}
            />
          ))}
      </Carousel>
    )}
  </div>
);
