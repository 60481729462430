import { useCallback } from 'react';
// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { ChunkyMessage as Component } from '@virginexperiencedays/components-v2/src/ChunkyMessage';
import type { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
// Utils
import {
  getPrismicLinkPath,
  type PrismicLink,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
// Types
import type { PrismicContext } from '../../types/context';

type ChunkyMessageProps = {
  slice: {
    primary: {
      overtitle: string;
      title_line_1: string;
      title_line_2: string;
      title_size: DisplayHeadingProps['size'];
      primary_cta_text: string;
      primary_cta_href: PrismicLink;
      secondary_cta_text: string;
      secondary_cta_href: PrismicLink;
    };
    slice_type: string;
  };
  context: PrismicContext;
};

const ChunkyMessage = ({ slice, context }: ChunkyMessageProps) => {
  const {
    primary: {
      overtitle: overTitle,
      title_line_1: titleLine1,
      title_line_2: titleLine2,
      title_size: titleSize,
      primary_cta_text,
      primary_cta_href,
      secondary_cta_text,
      secondary_cta_href,
    },
    slice_type,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;

  const primaryLink = getPrismicLinkPath(primary_cta_href);
  const secondaryLink = getPrismicLinkPath(secondary_cta_href);

  const handlePrimaryCtaClick = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: primaryLink,
      sliceType: slice_type,
      pageType,
    });
  }, [primaryLink]);

  const handleSecondaryCtaClick = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: secondaryLink,
      sliceType: slice_type,
      pageType,
    });
  }, [secondaryLink]);

  if (isFaceted) return null;

  return (
    <Container>
      <Component
        overTitle={overTitle}
        titleLine1={titleLine1}
        titleLine2={titleLine2}
        titleSize={titleSize}
        primaryCta={primaryLink ? { href: primaryLink, text: primary_cta_text } : null}
        secondaryCta={secondaryLink ? { href: secondaryLink, text: secondary_cta_text } : null}
        onPrimaryCtaClick={handlePrimaryCtaClick}
        onSecondaryCtaClick={handleSecondaryCtaClick}
      />
    </Container>
  );
};

export default ChunkyMessage;
