import { ReactNode, RefObject, useState } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { MobileCTA } from '../../layout/Carousel/MobileCTA';
import { CarouselHeading } from '../../layout/Carousel/Heading';
import { TabBar } from '../../TabGroup/TabBar';

import { CarouselHero, type CarouselWithTabsHeroLogo } from './CarouselHero';

export type Props = {
  carouselRef: RefObject<Element[]>;
  children: Array<ReactNode>;
  lastChildRef: RefObject<Element[]>;
  onTabClick: (tabName: string) => void;
  tabs: { id: string; title: string; imageUrl?: string; imageAlt?: string }[];
  className?: string;
  ctaTitle?: string;
  dataTestId?: string;
  debounceTime?: number;
  description?: string;
  firstChildRef: RefObject<Element[]>;
  heroImageIsPriority?: boolean;
  homeLink?: string;
  href?: string;
  isHrefExternal?: boolean;
  onClick?: () => void;
  showNavigationArrows?: boolean;
  title?: string;
  variant?: CarouselWithTabsHeroLogo;
  withHeading?: boolean;
  withHero?: boolean;
};

/**
 * This is a variant of NewCarousel, but has a TabBar. For SEO purposes, all
 * items must be rendered into the markup, with the activeTab only hiding the
 * others with CSS.
 *
 * This is lifted up into the consumer: the consumer is in charge of rendering
 * an array of Track nodes and hiding all but the active Track with CSS.
 */
export const CarouselWithTabs = ({
  carouselRef,
  children,
  lastChildRef,
  onTabClick,
  tabs,
  className,
  ctaTitle,
  dataTestId = 'carousel-with-tabs',
  debounceTime,
  description,
  firstChildRef,
  heroImageIsPriority,
  homeLink = '',
  href,
  isHrefExternal,
  onClick = null,
  showNavigationArrows = true,
  title,
  variant,
  withHeading = true,
  withHero,
}: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!tabs?.length) return null;

  return (
    <div className={className} data-testid={dataTestId}>
      {withHero && (
        <CarouselHero
          items={tabs}
          activeIndex={activeTab}
          className="mb-6"
          homeLink={homeLink}
          href={href}
          imageIsPriority={heroImageIsPriority}
          variant={variant}
        />
      )}

      {withHeading && (
        <CarouselHeading
          className={cn('text-2xl', getCnHeading(variant))}
          carouselRef={carouselRef}
          firstChildRef={firstChildRef}
          lastChildRef={lastChildRef}
          activeRefIndex={activeTab}
          title={title}
          description={description}
          ctaTitle={ctaTitle}
          href={href}
          isHrefExternal={isHrefExternal}
          debounceTime={debounceTime}
          showNavigationArrows={showNavigationArrows}
          onClick={onClick}
          ctaHiddenOnMobile
        />
      )}

      <TabBar
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onTabClick={onTabClick}
        variant={variant}
      />

      {/* We expect children to be an array of Track nodes */}
      <div className={cn('[&>ul]:hidden', cnTrack?.[activeTab + 1])}>{children}</div>

      {withHeading && ctaTitle && (
        <MobileCTA
          ctaTitle={ctaTitle}
          href={href}
          isHrefExternal={isHrefExternal}
          onClick={onClick}
        />
      )}
    </div>
  );
};

const getCnHeading = (variant?: Props['variant']) => {
  switch (variant) {
    case 'Black Friday':
      return 'hidden mb-6 md:block';
    default:
      return 'mb-4 md:mb-2';
  }
};

// ideally, we shouldn't overload the tabs
const cnTrack = {
  1: '[&>ul:nth-child(1)]:flex',
  2: '[&>ul:nth-child(2)]:flex',
  3: '[&>ul:nth-child(3)]:flex',
  4: '[&>ul:nth-child(4)]:flex',
  5: '[&>ul:nth-child(5)]:flex',
  6: '[&>ul:nth-child(6)]:flex',
  7: '[&>ul:nth-child(7)]:flex',
  8: '[&>ul:nth-child(8)]:flex',
  9: '[&>ul:nth-child(9)]:flex',
  10: '[&>ul:nth-child(10)]:flex',
  11: '[&>ul:nth-child(11)]:flex',
  12: '[&>ul:nth-child(12)]:flex',
  13: '[&>ul:nth-child(13)]:flex',
  14: '[&>ul:nth-child(14)]:flex',
  15: '[&>ul:nth-child(15)]:flex',
};
