import { itemToNavItem } from '@virginexperiencedays/search/utils/itemToNavItem';
import { findSibling } from '@virginexperiencedays/search/utils/findSibling';
import { categoryPrefixes } from '@virginexperiencedays/search/constants';
import type { HierarchicalFacet } from '@virginexperiencedays/search/types';

import type { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { getCategoryPrefix } from '../../../../utils/routing/getCategoryPrefix';
import { getCategoriesWithNameOverride } from '../../../../utils/getCategoriesWithNameOverride';

import { useCategoryPageContext } from '../CategoryListingPage/CategoryPageContext';
import { CategoryNuggets } from '../CategoryNuggets';

type SiblingCategoriesWidgetProps = {
  renderByDefault?: boolean;
  items: HierarchicalFacet[];
  categoryPrefix?: categoryPrefixes;
  isWrapped?: boolean;
  title?: string;
  titleLvl?: Exclude<DisplayHeadingProps['as'], 'h1'>;
  description?: string;
  // Takes control whether to render the UI no matter level, or actually when on lvl2 (default behaviour)
  shouldRenderOnLvl1?: boolean;
  slugNameMap?: Record<string, string>;
  onClick?: (href: string, data?: any) => void;
};
export const SiblingCategoriesWidget = ({
  renderByDefault,
  isWrapped,
  items,
  categoryPrefix,
  shouldRenderOnLvl1 = false,
  title = null,
  titleLvl = null,
  description = null,
  onClick = null,
  slugNameMap,
}: SiblingCategoriesWidgetProps) => {
  if (!items) return null;

  const { seoOverridesUrl, categoryType } = useCategoryPageContext();

  // Starts only from lvl2
  const lvl2 = items?.find((item) => item.isRefined)?.data;
  const isOnLvl2 = lvl2?.find((item) => item.isRefined);

  const categoryOptions = {
    categoryPrefix: getCategoryPrefix({
      isOverride: !!seoOverridesUrl,
      categoryPrefix,
      categoryType,
    }),
  };

  const navigationItems = () => {
    const targetFacet = isOnLvl2 ? lvl2 : items;
    const overriddenCategories = getCategoriesWithNameOverride(
      findSibling<HierarchicalFacet>(targetFacet),
      slugNameMap
    );

    if (renderByDefault || shouldRenderOnLvl1) {
      return itemToNavItem(overriddenCategories, categoryOptions);
    }

    return null;
  };

  return (
    <CategoryNuggets
      hasTitle
      title={title}
      titleLvl={titleLvl}
      description={description}
      isWrapped={isWrapped}
      navigationItems={navigationItems() ?? null}
      onClick={onClick}
    />
  );
};
