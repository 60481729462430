export const getDateFromDaysByUnix = (date: number, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getUnixTimeByDate = (dateString?: Date) => {
  if (!dateString) {
    return Date.now();
  }
  return dateString.getTime();
};

export const getUnixTimeInSeconds = () => {
  return Math.floor(getUnixTimeByDate() / 1000);
};
