import type { HierarchicalFacet, RefinementListItem } from '@virginexperiencedays/search/types';

export const getCategoriesWithNameOverride = (
  categories: HierarchicalFacet['data'] | { name: string; href: string }[],
  categoriesNameMap: Record<string, string>
): HierarchicalFacet[] => {
  if (!Array.isArray(categories)) return [];
  if (!categoriesNameMap) return categories as HierarchicalFacet[];

  return categories.map((category) => {
    const overrideName = categoriesNameMap[category.name];
    return overrideName ? { ...category, name: overrideName } : category;
  });
};

export const getCategoriesWithNameOverrideNew = (
  categories: RefinementListItem[],
  categoriesNameMap: Record<string, string>
): RefinementListItem[] => {
  if (!categoriesNameMap) return categories;

  return categories.map(({ value, label, ...rest }) => {
    const overrideName = categoriesNameMap[value.split('/').pop()] || label;
    return { value, label: overrideName, ...rest };
  });
};
