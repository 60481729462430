import { cn } from '@virginexperiencedays/tailwind';

import { Nugget } from '../Nugget';

export type NuggetModuleItemProps = {
  name: string;
  href: string;
  isHrefExternal?: boolean;
};

export type NuggetModuleProps = {
  className?: string;
  navigationItems: NuggetModuleItemProps[];
  isWrapped?: boolean;
  onClick?: (href: string, data?: any) => void;
  withNegativeMargin?: boolean;
};

export const NuggetModule = ({
  className,
  navigationItems,
  isWrapped = false,
  onClick = () => null,
  withNegativeMargin = true,
}: NuggetModuleProps) => {
  return (
    navigationItems.length > 0 && (
      <div className={cn(!isWrapped && withNegativeMargin && '-mx-4 my-0 md:-mx-10')}>
        <ul
          className={cn(
            'hide-scrollbar mb-0 flex items-center justify-start gap-2 pl-0',
            isWrapped ? 'flex-wrap' : 'flex-nowrap',
            !isWrapped && withNegativeMargin && 'px-4 py-0 md:px-10',
            'overflow-y-hidden overflow-x-scroll lg:flex-wrap',
            className
          )}
          data-testid="nugget-module"
        >
          {navigationItems.map(({ name, href, isHrefExternal }) => (
            <li key={href}>
              <Nugget
                className={cn(
                  'inline-block rounded px-4 py-2 capitalize',
                  !isWrapped && 'whitespace-nowrap'
                )}
                asLink
                testId="nugget-module-item"
                href={href}
                isHrefExternal={isHrefExternal}
                prefetch={false}
                onClick={() => {
                  onClick && onClick(href, { name });
                }}
              >
                {name}
              </Nugget>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};
