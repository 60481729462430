import { DisplayCard } from '@virginexperiencedays/components-v2/src/cards/Display';
import { BasicCard } from '@virginexperiencedays/components-v2/src/cards/Basic';
import { CategoryCard } from '@virginexperiencedays/components-v2/src/cards/Category';
import { TextOverCard } from '@virginexperiencedays/components-v2/src/cards/TextOver';
import { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
import { VisualHeadingProps } from '@virginexperiencedays/components-v2/src/typography/VisualHeading';
import type { ResponsiveAspectRatio } from '@virginexperiencedays/components-v2/src/utils/mapAspectRatio';

import { createLinkedPath } from '@virginexperiencedays/header-2024/src/utils/cms/createLinkedPath';

import type { CardType, CategoryLinkType } from '../types/slices';

export const CardsFactory = ({
  aspectRatioObj,
  card_type,
  card_title_as,
  card_hero_title_as,
  item,
  priority,
  onClick,
}: {
  aspectRatioObj: ResponsiveAspectRatio;
  card_type: CardType;
  card_title_as?: VisualHeadingProps['as'];
  card_hero_title_as?: DisplayHeadingProps['as'];
  item: CategoryLinkType;
  priority?: boolean;
  /** Tracking, etc */
  onClick?: (e: any) => void;
}) => {
  const normalizedUid = item?.content_link?.data?.uid.startsWith('/')
    ? item?.content_link?.data?.uid?.replace('/', '')
    : item?.content_link?.data?.uid;
  const propsObject = {
    aspectRatio: aspectRatioObj,
    titleAs: card_title_as,
    title:
      item.title_override ??
      item.content_link?.data?.display_title_override ??
      item.content_link?.data?.title,
    //@TODO: Revisit that once migrated on new URLs, as right now slugs in prismic are stored in old format, and we would have to do a huge lookup in order to find a proper hierarchy for that
    href: createLinkedPath(item?.content_link?.type, normalizedUid),
    isHrefExternal: item.is_url_external,
    src: item.image_override?.url
      ? item.image_override.url
      : item?.content_link?.data?.og_image?.url ?? item.content_link?.data?.legacy_og_image,
  };
  switch (card_type) {
    case 'display_card':
      return (
        <DisplayCard
          {...propsObject}
          heroTitle={propsObject.title}
          heroTitleAs={card_hero_title_as}
          ctaText={item.cta_text ?? 'Shop Now'}
          title={item.hero_title}
          onClick={onClick}
          priority={priority}
        />
      );
    case 'category_card':
      return (
        <CategoryCard
          {...propsObject}
          heroTitle={item?.hero_title}
          description={item.description_override ?? item?.content_link?.data?.og_description}
          count={null} // post mvp feature
          onClick={onClick}
          priority={priority}
        />
      );

    case 'text_over_card':
      return (
        <TextOverCard
          {...propsObject}
          description={item.description_override ?? item?.content_link?.data?.og_description}
          onClick={onClick}
          priority={priority}
        />
      );

    case 'basic_card':
      return (
        <BasicCard
          {...propsObject}
          description={item.description_override ?? item?.content_link?.data?.og_description}
          ctaText={item?.cta_text}
          onClick={onClick}
          priority={priority}
        />
      );
    default:
      return null;
  }
};
