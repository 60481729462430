import { cn } from '@virginexperiencedays/tailwind';

import { type HeightSize, mapHeight, type ResponsiveHeight } from '../../utils/mapHeight';

export type SpacerBreakpoints = HeightSize | ResponsiveHeight;

export type SpacerProps = {
  size: SpacerBreakpoints;
};

export const Spacer = ({ size }: SpacerProps) => {
  // NOTE: class name "spacer-component" is required as it is referenced from other components (e.g. Stack)
  // @TODO: Refactor, this shouldn't be done this way
  return (
    <div className={cn('block w-full', 'spacer-component', mapHeight(size))} data-testid="spacer" />
  );
};
