// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { ListOfLinks as Component } from '@virginexperiencedays/components-v2/src/ListOfLinks';
// Types
import type { PrismicContext } from '../../types/context';

type ListOfLinksProps = {
  slice: {
    primary: {
      title: string;
    };
  };
  context: PrismicContext;
};

const ListOfLinks = ({ slice, context }: ListOfLinksProps) => {
  const { listOfLinks } = context;
  const { primary } = slice;

  if (!listOfLinks?.length) return null;

  return (
    <Container>
      <Component links={listOfLinks} title={primary.title} />
    </Container>
  );
};

export default ListOfLinks;
