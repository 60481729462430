import { useCallback } from 'react';
import type { ImageField } from '@prismicio/types';
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { HeroStyle02 as Component } from '@virginexperiencedays/components-v2/src/banners/HeroStyle/Style2';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import type { PrismicContext } from '../../types/context';

type HeroStyle02Props = {
  slice: {
    primary: {
      title: string;
      overtitle?: string;
      body: string;
      image: ImageField;
      mobile_image: ImageField;
      image_above_fold?: boolean;
      cta_text?: string;
      cta_href?: any;
    };
    slice_type: string;
    position?: number;
  };
  context: PrismicContext;
};

const HeroStyle02 = ({ slice, context }: HeroStyle02Props) => {
  const {
    primary: { title, overtitle, body, image, image_above_fold, mobile_image, cta_text, cta_href },
    slice_type,
    position,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;

  const link = getPrismicLinkPath(cta_href) || '#';
  const isLinkExternal = cta_href.link_type !== 'Document';

  const handleTracking = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: link,
      sliceType: slice_type,
      pageType,
    });
  }, [link, lastSlug, slice_type, pageType]);

  if (isFaceted) return null;

  const lcpPriority = position <= LCP_PREFETCH_SLICE_COUNT;

  // required as Primsic's initial default value is always null
  const imageIsPriority = lcpPriority || image_above_fold === null || !!image_above_fold;

  return (
    <Container>
      <Component
        title={title}
        overTitle={overtitle}
        body={body}
        imageUrl={image?.url}
        imageAlt={image?.alt}
        mobileImageUrl={mobile_image?.url}
        mobileImageAlt={mobile_image?.alt}
        imageIsPriority={imageIsPriority}
        ctaLabel={cta_text || 'Shop Now'}
        link={link}
        isLinkExternal={isLinkExternal}
        onClick={handleTracking}
      />
    </Container>
  );
};

export default HeroStyle02;
