// Components
import {
  Spacer as Component,
  type SpacerBreakpoints,
} from '@virginexperiencedays/components-v2/src/layout/Spacer';
// Types
import type { PrismicContext } from '../../types/context';
import type { HeightSize } from '@virginexperiencedays/components-v2/src/utils/mapHeight';

type CmsSpacerSize = 'none' | 's' | 'm' | 'l' | 'xl' | 'xxl';

type SpacerProps = {
  slice: {
    primary: {
      override_size: CmsSpacerSize;
      mobile_size: CmsSpacerSize;
      tablet_size: CmsSpacerSize;
      desktop_size: CmsSpacerSize;
      wide_size: CmsSpacerSize;
    };
  };
  context: PrismicContext;
};

const mapCmsSpacerSize = (size: CmsSpacerSize): HeightSize => {
  const sizeMap = {
    none: 'none',
    s: 'sm',
    m: 'md',
    l: 'lg',
    xl: 'xl',
    xxl: '2xl',
  };
  return sizeMap?.[size] as HeightSize;
};

const Spacer = ({ slice, context }: SpacerProps) => {
  const {
    primary: { override_size, mobile_size, tablet_size, desktop_size, wide_size },
  } = slice;
  const { hide, isFaceted } = context;

  // if override_size is provided, use it completely
  // otherwise, build the SpacerBreakpoints object
  const spacerSize: SpacerBreakpoints =
    typeof override_size === 'string'
      ? mapCmsSpacerSize(override_size)
      : {
          mobile: mapCmsSpacerSize(mobile_size),
          tablet: mapCmsSpacerSize(tablet_size),
          desktop: mapCmsSpacerSize(desktop_size),
          wide: mapCmsSpacerSize(wide_size),
        };

  if (isFaceted || hide) {
    return null;
  }

  return <Component size={spacerSize} />;
};

export default Spacer;
