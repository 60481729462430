// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import useFeatureFlags from '@virginexperiencedays/feature-flags';
import { CategoryNuggets } from '@virginexperiencedays/web/components/pages/search/CategoryNuggets';
import { SiblingCategoriesWidget } from '@virginexperiencedays/web/components/pages/search/SiblingCategoriesWidget';
import { SiblingCategoriesWidgetNew } from '@virginexperiencedays/web/components/pages/search/SiblingCategoriesWidget/index-new';
import { SubCategoriesWidget } from '@virginexperiencedays/web/components/pages/search/SubCategoriesWidget';
import { SubCategoriesWidgetNew } from '@virginexperiencedays/web/components/pages/search/SubCategoriesWidget/index-new';

// Utils
import { formatNavigationItems } from './formatNavigationItems';

// Types
import type { AlgoliaNuggetsVariant, CustomNuggetsVariant } from './types';

type NuggetSliceProps = AlgoliaNuggetsVariant | CustomNuggetsVariant;

const NuggetSlice = ({ slice, context }: NuggetSliceProps) => {
  const [isEnabled] = useFeatureFlags();
  const {
    primary: { is_wrapped, title, title_level, description },
    variation,
    slice_type,
  } = slice;
  const {
    mainCategoryItems,
    categoryPrefix,
    slug,
    hide,
    isFaceted,
    slugNameMap,
    pageType,
    trackSlice,
  } = context;

  const onClick = (href: string, data: any) => {
    // let original event propagate

    // tracking
    trackSlice?.({
      slug: Array.isArray(slug) ? slug[slug.length - 1] : slug,
      label: data?.name ?? href,
      sliceType: slice_type,
      variation,
      pageType,
    });
  };

  if (isFaceted || hide) {
    return null;
  }

  switch (variation) {
    case 'siblingCategories':
      return (
        <Container>
          {isEnabled('FF_new_filters') ? (
            <SiblingCategoriesWidgetNew
              title={title}
              titleLvl={title_level}
              description={description}
              isWrapped={is_wrapped}
              slugNameMap={slugNameMap}
              shouldRenderOnLvl1
              onClick={onClick}
              serverSlug={slug as string[]}
            />
          ) : (
            <SiblingCategoriesWidget
              title={title}
              titleLvl={title_level}
              description={description}
              isWrapped={is_wrapped}
              items={mainCategoryItems}
              slugNameMap={slugNameMap}
              categoryPrefix={categoryPrefix}
              shouldRenderOnLvl1
              onClick={onClick}
            />
          )}
        </Container>
      );
    case 'childCategories':
      return (
        <Container>
          {isEnabled('FF_new_filters') ? (
            <SubCategoriesWidgetNew
              title={title}
              titleLvl={title_level}
              description={description}
              isWrapped={is_wrapped}
              slugNameMap={slugNameMap}
              onClick={onClick}
              serverSlug={slug as string[]}
            />
          ) : (
            <SubCategoriesWidget
              title={title}
              titleLvl={title_level}
              description={description}
              isWrapped={is_wrapped}
              items={mainCategoryItems}
              slugNameMap={slugNameMap}
              categoryPrefix={categoryPrefix}
              onClick={onClick}
            />
          )}
        </Container>
      );
    case 'default':
      return (
        <Container>
          <CategoryNuggets
            hasTitle
            title={title}
            titleLvl={title_level}
            description={description}
            isWrapped={is_wrapped}
            navigationItems={formatNavigationItems(slice?.items)}
            detectNewURL={false}
            onClick={onClick}
          />
        </Container>
      );
    default:
      return null;
  }
};

export default NuggetSlice;
