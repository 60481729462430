/**
This util finds siblings in hierarchical menu's recursively
*/
export function findSibling<
  T extends {
    isRefined: boolean;
    data?: T[];
  }
>(arr: T[]): T[] {
  const next = arr?.find(
    (item) => item.isRefined && item?.data?.some(({ isRefined }) => isRefined)
  );

  if (!next) {
    return arr.filter((item) => !item.isRefined);
  }

  return findSibling(next?.data);
}
