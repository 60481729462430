import { useCallback } from 'react';
import { ImageField } from '@prismicio/types';

import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { HeroStyle05 as Component } from '@virginexperiencedays/components-v2/src/banners/HeroStyle/Style5';
import {
  getPrismicLinkPath,
  PrismicLink,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import {
  defaultHeroStyle05BgImage,
  LCP_PREFETCH_SLICE_COUNT,
  maskSquircleHeroStyle05,
} from '../../constants';
import { PrismicContext } from '../../types/context';

type HeroStyle05Props = {
  slice: {
    primary: {
      title: string;
      body: string;
      cta_text?: string;
      cta_href?: PrismicLink;
      image_above_fold?: boolean;
      image_1: ImageField;
      image_2: ImageField;
      image_3: ImageField;
      bg_image: ImageField;
      bg_image_tablet: ImageField;
    };
    slice_type: string;
    position?: number;
  };
  context: PrismicContext;
};

const HeroStyle05 = ({ slice, context }: HeroStyle05Props) => {
  const {
    primary: {
      title,
      body,
      cta_text,
      cta_href,
      image_above_fold,
      image_1,
      image_2,
      image_3,
      bg_image,
      bg_image_tablet,
    },
    slice_type,
    position,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;

  // prismic document or web url only
  const link = 'uid' in cta_href || 'url' in cta_href ? getPrismicLinkPath(cta_href) : null;

  const handleCtaClick = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: link,
      sliceType: slice_type,
      pageType,
    });
  }, [link]);

  if (isFaceted) return null;

  const lcpPriority = position <= LCP_PREFETCH_SLICE_COUNT;

  // required as Primsic's initial default value is always null
  const imageIsPriority = lcpPriority || image_above_fold === null || !!image_above_fold;

  const maskImages =
    maskSquircleHeroStyle05[process.env.NODE_ENV] ?? maskSquircleHeroStyle05.production;

  const defaultBgImage =
    defaultHeroStyle05BgImage[process.env.NODE_ENV] ?? defaultHeroStyle05BgImage.production;

  return (
    <Container>
      <Component
        title={title}
        body={body}
        ctaLabel={cta_text}
        ctaLink={link}
        onClick={link ? handleCtaClick : null}
        imageIsPriority={imageIsPriority}
        bgImage={{ desktop: bg_image, tablet: bg_image_tablet } as any}
        images={
          [
            { ...image_1, id: 'image-1' },
            { ...image_2, id: 'image-2' },
            { ...image_3, id: 'image-3' },
          ] as any
        }
        defaultBgImage={defaultBgImage}
        maskImages={maskImages}
      />
    </Container>
  );
};

export default HeroStyle05;
