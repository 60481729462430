import { ReactNode } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

export const Overtitle = ({ className, children }: { children: ReactNode; className?: string }) => (
  <span
    className={cn(
      'font-inter leading-base block text-balance text-sm font-semibold uppercase tracking-widest',
      'text-brand',
      className
    )}
  >
    {children}
  </span>
);
