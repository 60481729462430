import { cn } from '@virginexperiencedays/tailwind';

import { BodyText } from '../typography/BodyText';
import { DisplayHeading, DisplayHeadingProps } from '../typography/DisplayHeading';

export type SliceHeaderProps = {
  title?: string;
  titleLvl?: DisplayHeadingProps['as'];
  description?: string;
};

export const SliceHeader = ({ title, titleLvl, description }: SliceHeaderProps) => {
  return (
    <div className="mb-6">
      {title && (
        <DisplayHeading
          className={cn(
            'text-neutral-strong mt-0 text-xl font-bold leading-[1.2]',
            description && 'mb-2'
          )}
          as={titleLvl ?? 'h2'}
        >
          {title}
        </DisplayHeading>
      )}
      {description && (
        <BodyText className="text-neutral m-0 break-words text-base font-normal leading-normal">
          {description}
        </BodyText>
      )}
    </div>
  );
};
