// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { CarouselWithIntro } from '@virginexperiencedays/components-v2/src/carousels/CarouselWithIntro';
// Utils
import { CardVariantsFactory, typeOptions } from '../../utils/CardVariantsFactory-v2';
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
// Types
import type { CarouselIntroSlotType } from '../../types/slices';
import type { PrismicContext } from '../../types/context';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';

const CarouselWithIntroSlot = ({
  slice,
  context,
}: {
  slice: CarouselIntroSlotType;
  context: PrismicContext;
}) => {
  const { primary, items, variation, position } = slice;
  const { lastSlug, hide, isFaceted, pageType, trackSlice, isProductCardsV2 } = context;

  const trackPrimaryCtaLink = () => {
    if (primary.primary_cta_link) {
      trackSlice?.({
        slug: lastSlug,
        label: getPrismicLinkPath(primary.primary_cta_link),
        sliceType: 'carousel_cta',
      });
    }
  };

  const endCardTrackCtaLink = () => {
    if (primary.end_card_cta_link) {
      trackSlice?.({
        pageType,
        slug: lastSlug,
        label: getPrismicLinkPath(primary.end_card_cta_link),
        sliceType: 'carousel_end_card_cta',
      });
    }
  };

  const endCardTrackOnReached = () => {
    if (primary.end_card_title) {
      trackSlice?.({
        pageType,
        slug: lastSlug,
        label: primary.end_card_title,
        sliceType: 'carousel_end_card_cta',
        action: 'Reached end card',
      });
    }
  };

  const endCardData = {
    displayEndCard: primary.display_end_card,
    endCardTitle: primary.end_card_title,
    endCardOverTitle: primary.end_card_over_title,
    endCardCtaTitle: primary.end_card_cta_title,
    endCardCtaLink: primary.end_card_cta_link && getPrismicLinkPath(primary.end_card_cta_link),
    endCardTrackCtaLink: () => endCardTrackCtaLink(),
    endCardTrackOnReached: () => endCardTrackOnReached(),
  };

  const carouselProps = {
    title: primary.title,
    overTitle: primary.over_title,
    href: primary.cta_href && getPrismicLinkPath(primary.cta_href),
    isHrefExternal: true,
    ctaTitle: primary.cta_title,
    primaryCtaTitle: primary.primary_cta_title,
    primaryCtaLink: primary.primary_cta_link && getPrismicLinkPath(primary.primary_cta_link),
    trackPrimaryCtaLink: trackPrimaryCtaLink,
    showNavigationArrows: primary.desktop_show_navigation_arrows,
  };

  const optionsObj = {
    variation,
    items,
    primary: {
      ...primary,
      /**
       * NOTE: Insert a default card title of h3 here as opposed to
       * CardVariantsFactory's default of h2, because CarouselStructure has its
       * own title instead of rendering each card directly.
       */
      card_title_as: primary.card_title_as ?? 'h3',
    },
  };

  if (isFaceted || hide) {
    return null;
  }

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <CardVariantsFactory
        Wrapper={CarouselWithIntro}
        wrapperProps={carouselProps}
        options={typeOptions(optionsObj, variation)}
        priority={priority}
        endCardData={endCardData}
        isProductCardsV2={isProductCardsV2}
      />
    </Container>
  );
};

export default CarouselWithIntroSlot;
