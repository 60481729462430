import { cn } from '@virginexperiencedays/tailwind';

//@TODO: Refactor this.. this component should get pure URL
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { LinkWrap } from '../../navigation/LinkWrap';
import { BodyText } from '../../typography/BodyText';
import { DisplayHeading } from '../../typography/DisplayHeading';

type BannerStyle = 'Primary' | 'Grey100' | 'Grey900';
type BannerIcon = {
  url: string;
  alt?: string;
  Mobile?: { url: string; alt: string };
};

type Banner = {
  title: string;
  body: string;
  url: any;
  icon: BannerIcon;
  style: BannerStyle;
  onClick: any;
};

export type IconBannerProps = {
  bannerOne: Banner;
  bannerTwo: Banner;
};

export const IconBanner = ({ bannerOne, bannerTwo }: IconBannerProps) => {
  const banners = [
    {
      url: bannerOne.url,
      style: bannerOne.style,
      icon: bannerOne.icon,
      title: bannerOne.title,
      body: bannerOne.body,
      onClick: bannerOne.onClick,
    },
    {
      url: bannerTwo.url,
      style: bannerTwo.style,
      icon: bannerTwo.icon,
      title: bannerTwo.title,
      body: bannerTwo.body,
      onClick: bannerTwo.onClick,
    },
  ];

  if (banners.some((banner) => !banner.title || !banner.body)) return null;

  return (
    <section>
      <div className="flex flex-col gap-4 px-4 md:flex-row md:px-10 xl:mx-auto xl:max-w-[1440px] xl:px-20">
        {banners.map((banner) => {
          const target = getPrismicLinkPath(banner.url);

          return (
            <IconBannerParent key={banner.title} target={target} banner={banner}>
              {/* eslint-disable-next-line */}
              <img
                className="hidden xl:block"
                data-testid="IconBannerIcon"
                src={banner.icon?.url}
                loading="lazy"
                decoding="async"
              />
              <div className="mx-2 my-6 flex flex-col items-center justify-center text-center sm:mx-4 xl:mx-0 xl:my-6 xl:items-start xl:text-left">
                <DisplayHeading className="text-base/tight" data-testid="IconBannerTitle" size="2">
                  {banner.title}
                </DisplayHeading>
                <BodyText
                  className="mt-1 text-base/normal font-normal"
                  data-testid="IconBannerBody"
                >
                  {banner.body}
                </BodyText>
              </div>
            </IconBannerParent>
          );
        })}
      </div>
    </section>
  );
};

const mapColorToStyle = (color: BannerStyle, hasTarget: boolean) => {
  // persistent colors regardles dark theme
  switch (color) {
    case 'Primary':
      return cn('bg-primary-700 text-white', hasTarget && 'hover:bg-primary-600');
    case 'Grey100':
      return cn('bg-grey-100 text-grey-800', hasTarget && 'hover:bg-primary-100');
    case 'Grey900':
      return cn('bg-grey-900 text-white', hasTarget && 'hover:bg-grey-700');
    default:
      return null;
  }
};

const styles = {
  banner:
    'flex min-h-[120px] flex-row items-center justify-center p-0 gap-6 gap-6 rounded no-underline w-auto sm:w-initial md:w-1/2 xl:px-10',
};

const IconBannerParent = ({ children, target, banner }) => {
  if (!target) {
    return (
      <div
        className={cn(styles.banner, mapColorToStyle(banner.style, false))}
        onClick={banner.onClick}
        data-testid="IconBanner"
      >
        {children}
      </div>
    );
  }
  return (
    <LinkWrap
      className={cn(styles.banner, mapColorToStyle(banner.style, true))}
      href={target}
      onClick={banner.onClick}
      data-testid="IconBanner"
    >
      {children}
    </LinkWrap>
  );
};
