import { useMemo } from 'react';

import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { FeaturedVisualCards as Component } from '@virginexperiencedays/components-v2/src/cards/FeaturedVisualCards';
import type { PrismicLink } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import { LCP_PREFETCH_CAROUSEL_COUNT, LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import { getContentLinkTitle, getContentLinkImage, getContentLinkUrl } from '../../utils/overrides';

import type { CardVariant, ContentRelationship } from '../../types/slices';
import type { PrismicContext } from '../../types/context';
import type { PrismicImage } from '../../types/image';

const TOTAL_POSTS = 3;

type FeaturedVisualCardsProps = {
  slice: {
    primary: {
      title: string;
      description: string;
    };
    slice_type: string;
    variation: CardVariant;
    items: {
      content_link: ContentRelationship['content_link'];
      title: string;
      overtitle: string;
      cta_text: string;
      cta_href: PrismicLink;
      image_override: PrismicImage;
    }[];
    position?: number;
  };
  context: PrismicContext;
};

const FeaturedVisualCards = ({ slice, context }: FeaturedVisualCardsProps) => {
  const {
    primary: { title: introTitle, description: introDescription },
    items,
    slice_type,
    position,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;

  const hasItems = Array.isArray(items) && items?.length >= TOTAL_POSTS;

  const posts = useMemo(
    () =>
      hasItems
        ? items
            .slice(0, TOTAL_POSTS)
            .map(
              ({
                content_link,
                title: titleOverride,
                overtitle: overTitleOverride,
                cta_text: ctaTextOverride,
                cta_href,
                image_override,
              }) => {
                const { data, type } = content_link;

                const title = titleOverride || getContentLinkTitle(content_link, data?.title);

                let overTitle = '';
                if (type === 'blog_post_page') overTitle = 'Blog';
                else if (type === 'post') overTitle = 'Things to do';

                const ctaText = ['blog_post_page', 'post'].includes(type) ? 'Read Now' : 'Shop Now';
                const ctaHref = getContentLinkUrl(content_link, cta_href);

                return {
                  title,
                  overTitle: overTitleOverride || overTitle,
                  ctaText: ctaTextOverride || ctaText,
                  ctaHref,
                  imageUrl: getContentLinkImage(content_link, image_override),
                  onClick: () => {
                    // let original event propagate

                    trackSlice?.({
                      slug: lastSlug,
                      label: title,
                      sliceType: slice_type,
                      pageType,
                    });
                  },
                };
              }
            )
        : [],
    [items]
  );

  if (isFaceted || !hasItems) return null;

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <div className="bg-background-primary-faded px-0 pb-20 pt-14">
      <Container>
        <Component
          title={introTitle}
          description={introDescription}
          items={posts}
          limit={TOTAL_POSTS}
          priority={priority}
          priorityCount={LCP_PREFETCH_CAROUSEL_COUNT}
        />
      </Container>
    </div>
  );
};

export default FeaturedVisualCards;
