import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';

import { LogoBlackFriday, MaskBlackFriday } from './VariantBlackFriday';

export type CarouselWithTabsHeroLogo = 'None' | 'Black Friday';

type HeroProps = {
  variant: Props['variant'];
  className?: string;
  homeLink?: string;
};

type Props = {
  items: { id: string; title: string; imageUrl?: string; imageAlt?: string }[];
  activeIndex?: number;
  className?: string;
  dataTestId?: string;
  homeLink?: string;
  href?: string;
  imageIsPriority?: boolean;
  variant?: CarouselWithTabsHeroLogo;
};

// const HERO_WIDTH = 1280;
const HERO_HEIGHT = 200;

export const CarouselHero = ({
  items,
  activeIndex = 0,
  className = null,
  dataTestId = 'carousel-with-tabs',
  homeLink = '',
  href,
  imageIsPriority = false,
  variant,
}: Props) => {
  const Tag = href ? LinkWrap : 'div';
  const wrapperProps = href ? { href } : {};

  if (!items?.length) return null;

  return (
    <Tag
      className={cn('relative block overflow-hidden', getCnWrapper(variant), className)}
      data-testid={`${dataTestId}-hero`}
      {...(wrapperProps as any)}
      style={{
        height: `${HERO_HEIGHT}px`,
      }}
    >
      <HeroMask variant={variant} className="relative left-0 top-0 z-[2] h-full w-full" />

      <HeroLogo
        variant={variant}
        className="absolute left-1/2 top-1/2 z-[3] max-w-none -translate-x-1/2 -translate-y-1/2"
        homeLink={homeLink}
      />

      {items.map(({ id, title, imageUrl, imageAlt }, index) => {
        const isActive = index === activeIndex;
        return (
          <div
            key={`carousel-with-tabs-hero-${id}`}
            data-testid={`${dataTestId}-hero-image`}
            className={cn(
              'absolute left-0 top-0 z-[0] h-full w-full opacity-0 [transition:opacity_0.4s_ease-in-out]',
              isActive && 'z-[1]',
              getCnItem(variant, isActive)
            )}
          >
            <Image
              className={cn('image:object-cover image:object-center')}
              src={imageUrl}
              alt={imageAlt || title}
              priority={imageIsPriority}
              fill
              style={{
                height: `${HERO_HEIGHT}px`,
              }}
            />
          </div>
        );
      })}
    </Tag>
  );
};

const HeroLogo = ({ variant, className, homeLink = '' }: HeroProps) => {
  switch (variant) {
    case 'Black Friday':
      return <LogoBlackFriday className={className} homeLink={homeLink} />;
    default:
      return null;
  }
};

const HeroMask = ({ variant, className }: HeroProps) => {
  switch (variant) {
    case 'Black Friday':
      return <MaskBlackFriday className={className} />;
    default:
      return null;
  }
};

const getCnWrapper = (logo?: CarouselWithTabsHeroLogo) => {
  switch (logo) {
    case 'Black Friday':
      return 'bg-grey-950';
    default:
      return 'bg-background-page';
  }
};

const getCnItem = (logo?: CarouselWithTabsHeroLogo, isActive?: boolean) => {
  switch (logo) {
    case 'Black Friday':
      return cn('grayscale', isActive && 'opacity-40');
    default:
      return cn(isActive && 'opacity-100');
  }
};
