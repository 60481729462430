import { cn } from '@virginexperiencedays/tailwind';

export type HeightSize = 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type ResponsiveHeight = {
  mobile?: HeightSize;
  tablet?: HeightSize;
  desktop?: HeightSize;
  wide?: HeightSize;
};

export const sizeConfig = {
  none: 'h-0',
  sm: 'h-4',
  md: 'h-6',
  lg: 'h-10',
  xl: 'h-14',
  '2xl': 'h-20',
};

const responsiveSizeConfig = {
  mobile: sizeConfig,
  tablet: {
    none: 'md:h-0',
    sm: 'md:h-4',
    md: 'md:h-6',
    lg: 'md:h-10',
    xl: 'md:h-14',
    '2xl': 'md:h-20',
  },
  desktop: {
    none: 'lg:h-0',
    sm: 'lg:h-4',
    md: 'lg:h-6',
    lg: 'lg:h-10',
    xl: 'lg:h-14',
    '2xl': 'lg:h-20',
  },

  wide: {
    none: 'xl:h-0',
    sm: 'xl:h-4',
    md: 'xl:h-6',
    lg: 'xl:h-10',
    xl: 'xl:h-14',
    '2xl': 'xl:h-20',
  },
};

// THIS SHOULD BE EXPANDED ONLY PER USAGE
// keep in mind that adding more values to this function will include them in final css bundle
// as when this function is compiled it uses all tailwind utilties even if  they are not used in the final styles
export function mapHeight(size: HeightSize | ResponsiveHeight): string {
  if (typeof size === 'string') {
    return sizeConfig[size];
  }

  const { mobile = 'sm', tablet, desktop, wide } = size;

  const mobileStyle = responsiveSizeConfig.mobile[mobile];
  const tabletStyle = tablet ? responsiveSizeConfig.tablet[tablet] : null;
  const desktopStyle = desktop ? responsiveSizeConfig.desktop[desktop] : null;
  const wideStyle = wide ? responsiveSizeConfig.wide[wide] : null;

  return cn(
    mobileStyle,
    tabletStyle && tabletStyle,
    desktopStyle && desktopStyle,
    wideStyle && wideStyle
  );
}
