import { cn } from '@virginexperiencedays/tailwind';

import { IconSquircle } from '../../../icons/IconSquircle';

const cnBfGradient = 'absolute w-full h-full';

const cnBfSquircle = 'absolute w-[178px] h-[178px] opacity-10';
const cnBfSquircleOne =
  'rotate-12 -top-[70px] -right-[130px] sm:-right-[50px] md:right-[5%] lg:right-[10%] xl:-top-[60px] xl:right-[15%]';
const cnBfSquircleTwo =
  'rotate-[30deg] -bottom-[50px] -left-[120px] sm:-bottom:-[30px] sm:-left-[100px] md:bottom-[70px] md:left-[5%] lg:left-[10%] xl:-bottom-[90px] xl:left-[15%]';

export const MaskBlackFriday = ({ className }) => (
  <div className={className} data-testid="carousel-hero-mask">
    <div
      className={cn(
        cnBfGradient,
        'before:absolute before:left-0 before:h-full before:w-[50px] before:rotate-180',
        'before:[background:linear-gradient(90deg,rgba(15,19,26,0)_0%,#0f131a_100%)] md:before:w-[100px]',
        'after:absolute after:right-0 after:h-full after:w-[50px]',
        'after:[background:linear-gradient(90deg,rgba(15,19,26,0)_0%,#0f131a_100%)] md:after:w-[100px]'
      )}
    />
    <div
      className={cn(
        cnBfGradient,
        'before:absolute before:top-0 before:h-[50px] before:w-full before:rotate-180',
        'before:[background:linear-gradient(180deg,rgba(15,19,26,0)_0%,#0f131a_100%)] md:before:h-[100px]',
        'after:absolute after:bottom-0 after:h-[50px] after:w-full',
        'after:[background:linear-gradient(180deg,rgba(15,19,26,0)_0%,#0f131a_100%)] md:after:h-[100px]'
      )}
    />
    <div className={cn(cnBfSquircle, cnBfSquircleOne)}>
      <IconSquircle fill="var(--persistent-white)" />
    </div>
    <div className={cn(cnBfSquircle, cnBfSquircleTwo)}>
      <IconSquircle fill="var(--persistent-white" />
    </div>
  </div>
);
