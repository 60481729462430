import { Accordion } from '../layout/Accordion';
import { LinkWrap } from '../navigation/LinkWrap';
import { DisplayHeading } from '../typography/DisplayHeading';

type LinkListProps = {
  links: {
    name: string;
    list: {
      name: string;
      url: string;
    }[];
  }[][];
  title: string;
};

export const ListOfLinks = ({ title, links }: LinkListProps) => {
  if (!title || !links?.length) return null;

  return (
    <>
      <DisplayHeading className="m-0 pb-4" as="h2" size="2" leading="snug">
        {title}
      </DisplayHeading>
      <div className="flex flex-col flex-wrap md:flex-row md:gap-6">
        {links.map((column) => (
          <div className="flex-1" key={column[0]?.name}>
            {column.map(({ name, list }) => {
              if (!name || !list) return null;
              return (
                <Accordion key={name} heading={name}>
                  <ul className="m-0 flex flex-col gap-2 p-0">
                    {list.map(({ name, url }) => (
                      <li className="text-base font-normal leading-relaxed" key={`${name}/${url}`}>
                        <LinkWrap className="text-inherit no-underline hover:underline" href={url}>
                          {name}
                        </LinkWrap>
                      </li>
                    ))}
                  </ul>
                </Accordion>
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};
