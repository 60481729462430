const domains = ['gmail.com', 'googlemail.com'];

/**
 * Normalizes an email address according to Google's
 * [Measurement Protocol specification](https://developers.google.com/analytics/devguides/collection/ga4/uid-data).
 * - Removes dots in the local part for Gmail addresses.
 * - Trims any leading or trailing whitespace.
 * @param inputString The email address to normalize.
 * @returns The normalized email address.
 */
export const normaliseEmail = (inputString: string): string | null => {
  if (!inputString) return null;

  let [localPart, domainPart] = inputString.trim().toLowerCase().split('@');

  if (!localPart || !domainPart) return null;

  if (domains.includes(domainPart)) localPart = localPart.replace(/\./g, '');

  return `${localPart}@${domainPart}`.replace(/\s+/g, '');
};

/**
 * Converts a string to a Uint8Array.
 * @param str The string to convert.
 * @returns The Uint8Array.
 */
const str2ab = (str: string): Uint8Array => {
  const encoder = new TextEncoder();
  return encoder.encode(str);
};

/**
 * Generates a SHA-256 hash of a string using SubtleCrypto.
 * @param inputString The string to hash.
 * @returns A promise that resolves to the hexadecimal hash string.
 */
const generateSHA256String = async (inputString: string): Promise<string> => {
  const data = str2ab(inputString);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

/**
 * Normalizes and hashes an email address using SHA-256.
 * @param email The email address to hash.
 * @returns A promise that resolves to the hashed email address.
 */
export const hashEmail = async (email: string): Promise<string> => {
  const normalizedEmail = normaliseEmail(email);
  if (!normalizedEmail) return null;
  return await generateSHA256String(normalizedEmail);
};
