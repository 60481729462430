import React from 'react';

import { itemToNavItem } from '@virginexperiencedays/search/utils/itemToNavItem';
import { findDeepest } from '@virginexperiencedays/search/utils/findDeepest';
import { categoryPrefixes } from '@virginexperiencedays/search/constants';
import type { HierarchicalFacet } from '@virginexperiencedays/search/types';

import type { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { getCategoriesWithNameOverride } from '../../../../utils/getCategoriesWithNameOverride';
import { getCategoryPrefix } from '../../../../utils/routing/getCategoryPrefix';

import { useCategoryPageContext } from '../CategoryListingPage/CategoryPageContext';
import { CategoryNuggets } from '../CategoryNuggets';

// Types
type SubCategoriesWidgetProps = {
  items: HierarchicalFacet[];
  categoryPrefix?: categoryPrefixes;
  isWrapped?: boolean;
  title?: string;
  titleLvl?: Exclude<DisplayHeadingProps['as'], 'h1'>;
  description?: string;
  slugNameMap?: Record<string, string>;
  onClick?: (href: string, data?: any) => void;
};

export const SubCategoriesWidget = ({
  items,
  categoryPrefix,
  isWrapped,
  title = null,
  titleLvl = null,
  description = null,
  onClick = null,
  slugNameMap,
}: SubCategoriesWidgetProps) => {
  const { seoOverridesUrl, categoryType } = useCategoryPageContext();

  if (!items) return null;

  const childCategories = findDeepest<HierarchicalFacet>(items);

  const categoryOptions = {
    categoryPrefix: getCategoryPrefix({
      isOverride: !!seoOverridesUrl,
      categoryPrefix,
      categoryType,
    }),
  };

  const navigationItems = itemToNavItem(
    getCategoriesWithNameOverride(childCategories?.data, slugNameMap),
    categoryOptions
  );

  return (
    <CategoryNuggets
      hasTitle
      title={title}
      titleLvl={titleLvl}
      description={description}
      isWrapped={isWrapped}
      navigationItems={navigationItems}
      onClick={onClick}
    />
  );
};
