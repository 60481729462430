import { cn } from '@virginexperiencedays/tailwind';
import { useAspectRatioSupported } from '@virginexperiencedays/hooks';

import { DisplayHeading, DisplayHeadingProps } from '../../typography/DisplayHeading';
import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  ResponsiveAspectRatio,
} from '../../utils/mapAspectRatio';

export type TextOverCardProps = {
  className?: string;
  /**
   * Title of the card
   */
  title: string;
  /** SEO Override */
  titleAs?: DisplayHeadingProps['as'];
  /** Description displayed under title */
  description?: string;
  /** set the aspect ratio of the image */
  aspectRatio?: ResponsiveAspectRatio;
  /** Image src of the card */
  src: string;
  /** alt text of the image */
  alt?: string;
  /** Where to link to */
  href: string;
  /** Whether to force external link behavior for href */
  isHrefExternal?: boolean;
  /** Size of content of the card */
  size?: 'regular' | 'large';
  /** Whether to force priority image preloading for LCP benefit */
  priority: boolean;
  /** Tracking, etc */
  onClick?: (e: any) => void;
};

export const TextOverCard = ({
  className,
  title,
  titleAs,
  description,
  src,
  alt,
  href,
  isHrefExternal,
  aspectRatio = '3/4',
  size = 'regular',
  priority,
  onClick = () => {},
}: TextOverCardProps) => {
  const { isSupported } = useAspectRatioSupported();
  return (
    <LinkWrap
      href={href}
      isExternal={isHrefExternal}
      className={cn(
        'bg-background-neutral group relative flex w-full flex-col justify-end overflow-hidden rounded no-underline',
        'after:absolute after:inset-0 after:bg-[linear-gradient(rgba(17,_17,_17,_0)_0%,_rgba(17,_17,_17,_0.008)_11.79%,_rgba(17,_17,_17,_0.03)_21.38%,_rgba(17,_17,_17,_0.07)_29.12%,_rgba(17,_17,_17,_0.12)_35.34%,_rgba(17,_17,_17,_0.18)_40.37%,_rgba(17,_17,_17,_0.25)_44.56%,_rgba(17,_17,_17,_0.33)_48.24%,_rgba(17,_17,_17,_0.41)_51.76%,_rgba(17,_17,_17,_0.498)_55.44%,_rgba(17,_17,_17,_0.584)_59.63%,_rgba(17,_17,_17,_0.675)_64.66%,_rgba(17,_17,_17,_0.76)_70.88%,_rgba(17,_17,_17,_0.847)_78.62%,_rgba(17,_17,_17,_0.925)_88.21%,_rgb(17,_17,_17)_100%)] after:opacity-50',
        isSupported && mapAspectRatio(aspectRatio),
        !isSupported && mapAspectRatioPolyfill(aspectRatio),
        className
      )}
      dataTestId="text-over-card"
      onClick={(e: any) => onClick(e)}
    >
      <Image
        className={cn(
          'image:object-cover absolute inset-0 h-full w-full transition-transform group-hover:scale-105',
          isSupported && mapAspectRatio(aspectRatio),
          !isSupported && mapAspectRatioPolyfill(aspectRatio)
        )}
        src={src}
        dataTestId="text-over-card-image"
        alt={alt ?? title}
        priority={priority}
        fill
      />
      <div
        className="absolute z-[1] line-clamp-5 max-h-full p-4 pb-6 pt-10 text-white transition-all group-hover:-translate-y-[10%] group-hover:opacity-80"
        data-testid="text-over-card-content-container"
      >
        <DisplayHeading
          className="font-semibold"
          as={titleAs}
          leading="normal"
          size={size === 'large' ? '4' : '5'}
          data-testid="text-over-card-title"
        >
          {title}
        </DisplayHeading>
        {description ? (
          <p
            className={cn('mt-2 text-sm leading-normal', size === 'large' && 'text-base')}
            data-testid="text-over-card-description"
          >
            {description}
          </p>
        ) : null}
      </div>
    </LinkWrap>
  );
};
