import React from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { VisualHeading } from '../../typography/VisualHeading';

export type DisplayCardProps = {
  title: string;
  imageUrl: string;
  overTitle?: string;
  ctaText?: string;
  ctaHref?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  priority?: boolean;
};

export const DisplayCard = ({
  overTitle,
  title,
  imageUrl,
  ctaText,
  ctaHref,
  onClick,
  priority,
}: DisplayCardProps) => {
  return (
    <LinkWrap
      className={cn(
        'relative flex h-full min-h-[342px] flex-col items-start justify-between overflow-hidden rounded px-4 py-6 no-underline md:min-h-[300px] lg:min-h-[380px]',
        'group after:absolute after:inset-0 after:bottom-1/2 after:z-[1] after:bg-[linear-gradient(180deg,_#111111_0%,_rgba(17,_17,_17,_0.991615)_11.79%,_rgba(17,_17,_17,_0.967585)_21.38%,_rgba(17,_17,_17,_0.9296)_29.12%,_rgba(17,_17,_17,_0.879348)_35.34%,_rgba(17,_17,_17,_0.818519)_40.37%,_rgba(17,_17,_17,_0.7488)_44.56%,_rgba(17,_17,_17,_0.671881)_48.24%,_rgba(17,_17,_17,_0.589452)_51.76%,_rgba(17,_17,_17,_0.5032)_55.44%,_rgba(17,_17,_17,_0.414815)_59.63%,_rgba(17,_17,_17,_0.325985)_64.66%,_rgba(17,_17,_17,_0.2384)_70.88%,_rgba(17,_17,_17,_0.153748)_78.62%,_rgba(17,_17,_17,_0.0737185)_88.21%,_rgba(17,_17,_17,_0)_100%)] after:opacity-50'
      )}
      dataTestId="featured-visual-card-link"
      href={ctaHref || '#'}
      onClick={onClick}
    >
      <Image
        className="image:object-cover absolute left-0 top-0 z-0 h-full w-full transition-transform duration-100 group-hover:scale-105"
        src={imageUrl}
        dataTestId="featured-visual-card-image"
        alt={title}
        fill
        priority={priority}
      />
      <div className="overflow-wrap-anywhere relative z-[2] flex-1 text-white">
        {!!overTitle && (
          <span
            className="font-inter m-0 text-sm font-normal leading-none"
            data-testid="featured-visual-card-overtitle"
          >
            {overTitle}
          </span>
        )}
        <VisualHeading
          className="mt-1 text-3xl font-normal leading-none tracking-normal shadow-sm sm:text-3xl"
          size="3"
          data-testid="featured-visual-card-title"
        >
          {title}
        </VisualHeading>
      </div>
      {!!ctaText && (
        <div
          className="text-grey-800 hover:text-link relative z-[2] mt-4 inline-block rounded bg-white px-4 py-2 text-sm font-semibold leading-none transition-colors"
          data-testid="featured-visual-card-cta"
        >
          {ctaText}
        </div>
      )}
    </LinkWrap>
  );
};
