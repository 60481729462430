import { useCallback } from 'react';
// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { HeroStyle04 as Component } from '@virginexperiencedays/components-v2/src/banners/HeroStyle/Style4';
// Utils
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
// Types
import { ImageField } from '@prismicio/types';
import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import { PrismicContext } from '../../types/context';

type HeroStyle04Props = {
  slice: {
    primary: {
      overtitle?: string;
      title: string;
      body: string;
      image: ImageField;
      mobile_image: ImageField;
      image_above_fold?: boolean;
      cta_1_text?: string;
      cta_1_href?: any;
      cta_1_anchor_id?: string;
      cta_2_text?: string;
      cta_2_href?: any;
      cta_2_anchor_id?: string;
    };
    slice_type: string;
    position?: number;
  };
  context: PrismicContext;
};

const HeroStyle04 = ({ slice, context }: HeroStyle04Props) => {
  const {
    primary: {
      title,
      overtitle,
      body,
      image,
      image_above_fold,
      mobile_image,
      cta_1_text,
      cta_1_href,
      cta_1_anchor_id,
      cta_2_text,
      cta_2_href,
      cta_2_anchor_id,
    },
    slice_type,
    position,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;

  const anchorId1 = cta_1_anchor_id ?? null;
  const anchorId2 = cta_2_anchor_id ?? null;

  const link1 = getPrismicLinkPath(cta_1_href) ?? null;
  const link2 = getPrismicLinkPath(cta_2_href) ?? null;

  const handleCta1Click = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: anchorId1 ?? link1,
      sliceType: slice_type,
      pageType,
    });
  }, [anchorId1, link1]);

  const handleCta2Click = useCallback(() => {
    // let original event propagate

    trackSlice?.({
      slug: lastSlug,
      label: anchorId2 ?? link2,
      sliceType: slice_type,
      pageType,
    });
  }, [anchorId2, link2]);

  if (isFaceted) return null;

  const lcpPriority = position <= LCP_PREFETCH_SLICE_COUNT;

  // required as Primsic's initial default value is always null
  const imageIsPriority = lcpPriority || image_above_fold === null || !!image_above_fold;

  return (
    <Container>
      <Component
        title={title}
        overTitle={overtitle}
        body={body}
        imageUrl={image?.url}
        imageAlt={image?.alt}
        mobileImageUrl={mobile_image?.url}
        mobileImageAlt={mobile_image?.alt}
        imageIsPriority={imageIsPriority}
        ctaArray={[
          {
            id: 1,
            label: cta_1_text,
            link: link1,
            anchorId: anchorId1,
            onClick: handleCta1Click,
          },
          {
            id: 2,
            label: cta_2_text,
            link: link2,
            anchorId: anchorId2,
            onClick: handleCta2Click,
          },
        ]}
      />
    </Container>
  );
};

export default HeroStyle04;
