import { itemToNavItemNew } from '@virginexperiencedays/search/utils/itemToNavItem';

import type { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { getCategoriesWithNameOverrideNew } from '../../../../utils/getCategoriesWithNameOverride';

import { useCategoryPageContext } from '../CategoryListingPage/CategoryPageContext';
import { CategoryNuggets } from '../CategoryNuggets';
import { getMainCategoryAttribute } from '../../../../utils/algolia/getMainCategoryAttribute';
import { getAttributeLvl } from '../../../../libs/algolia/getAttributeLvl';
import type { AlgoliaAttribute } from '../../../../libs/algolia/stateToRoute';
import { isNonHierarchicalCategory } from '../../../../libs/algolia/isNonHierarchicalCategory';
import { useCurrentCategoryItems } from '../../../../libs/algolia/hooks/useCurrentCategoryItems';

type SiblingCategoriesWidgetProps = {
  renderByDefault?: boolean;
  isWrapped?: boolean;
  title?: string;
  titleLvl?: Exclude<DisplayHeadingProps['as'], 'h1'>;
  description?: string;
  // Takes control whether to render the UI no matter level, or actually when on lvl2 (default behaviour)
  shouldRenderOnLvl1?: boolean;
  slugNameMap?: Record<string, string>;
  onClick?: (href: string, data?: any) => void;
  serverSlug: string[];
};

export const SiblingCategoriesWidgetNew = ({
  renderByDefault,
  isWrapped,
  shouldRenderOnLvl1 = false,
  title = null,
  titleLvl = null,
  description = null,
  onClick = null,
  slugNameMap,
  serverSlug,
}: SiblingCategoriesWidgetProps) => {
  const { categoryType } = useCategoryPageContext();
  const attributes = getMainCategoryAttribute(categoryType);
  const currentLvl = serverSlug.length - 1;
  const isNotFirstLevel = currentLvl > 1;
  const nextHierarchicalAttribute = isNonHierarchicalCategory(categoryType)
    ? 0
    : getAttributeLvl(categoryType, categoryType, currentLvl) - (isNotFirstLevel ? 0 : 1);

  const { items } = useCurrentCategoryItems({
    attribute: attributes[nextHierarchicalAttribute] as AlgoliaAttribute,
  });

  const siblingCategories = items.filter(({ value, isRefined }) => {
    const parts = value.split('/').slice(0, currentLvl);
    const slugParts = serverSlug.slice(0, currentLvl);

    return parts.join('/') === slugParts.join('/') && !isRefined;
  });

  if (!siblingCategories.length) return null;

  const navigationItems = () => {
    const overriddenCategories = getCategoriesWithNameOverrideNew(siblingCategories, slugNameMap);

    if (renderByDefault || shouldRenderOnLvl1) {
      return itemToNavItemNew(overriddenCategories);
    }

    return null;
  };

  return (
    <CategoryNuggets
      hasTitle
      title={title}
      titleLvl={titleLvl}
      description={description}
      isWrapped={isWrapped}
      navigationItems={navigationItems() ?? null}
      onClick={onClick}
    />
  );
};
