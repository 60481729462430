import React from 'react';

import { itemToNavItemNew } from '@virginexperiencedays/search/utils/itemToNavItem';

import type { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { getCategoriesWithNameOverrideNew } from '../../../../utils/getCategoriesWithNameOverride';

import { useCategoryPageContext } from '../CategoryListingPage/CategoryPageContext';
import { CategoryNuggets } from '../CategoryNuggets';
import { getAttributeLvl } from '../../../../libs/algolia/getAttributeLvl';
import { getMainCategoryAttribute } from '../../../../utils/algolia/getMainCategoryAttribute';
import { AlgoliaAttribute } from '../../../../libs/algolia/stateToRoute';
import { isNonHierarchicalCategory } from '../../../../libs/algolia/isNonHierarchicalCategory';
import { useCurrentCategoryItems } from '../../../../libs/algolia/hooks/useCurrentCategoryItems';

// Types
type SubCategoriesWidgetProps = {
  isWrapped?: boolean;
  title?: string;
  titleLvl?: Exclude<DisplayHeadingProps['as'], 'h1'>;
  description?: string;
  slugNameMap?: Record<string, string>;
  onClick?: (href: string, data?: any) => void;
  serverSlug: string[];
};

export const SubCategoriesWidgetNew = ({
  isWrapped,
  title = null,
  titleLvl = null,
  description = null,
  onClick = null,
  slugNameMap,
  serverSlug,
}: SubCategoriesWidgetProps) => {
  const { categoryType } = useCategoryPageContext();
  const attributes = getMainCategoryAttribute(categoryType);
  const currentLvl = serverSlug.length - 1;
  const nextHierarchicalAttribute = isNonHierarchicalCategory(categoryType)
    ? 0
    : getAttributeLvl(categoryType, categoryType, currentLvl);

  const { items } = useCurrentCategoryItems({
    attribute: attributes[nextHierarchicalAttribute] as AlgoliaAttribute,
  });

  const childCategories = items.filter(({ value, isRefined }) => {
    return value.startsWith(serverSlug.join('/')) && !isRefined;
  });

  if (!childCategories?.length) return null;

  const navigationItems = itemToNavItemNew(
    getCategoriesWithNameOverrideNew(childCategories, slugNameMap)
  );

  return (
    <CategoryNuggets
      hasTitle
      title={title}
      titleLvl={titleLvl}
      description={description}
      isWrapped={isWrapped}
      navigationItems={navigationItems}
      onClick={onClick}
    />
  );
};
