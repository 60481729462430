import { Columns } from '@virginexperiencedays/components-v2/src/layout/Columns';
import { SliceHeader } from '@virginexperiencedays/components-v2/src/SliceHeader';

const ColumnStructureWrapper = ({ title, title_level, description, collapse_below, children }) => {
  return (
    <div>
      <SliceHeader title={title} titleLvl={title_level} description={description} />
      <Columns collapseBelow={collapse_below === 'none' ? null : collapse_below}>
        {children}
      </Columns>
    </div>
  );
};

export default ColumnStructureWrapper;
