import { LinkButton } from '../../navigation/LinkButton';

type MobileCtaProps = {
  ctaTitle: string;
  href?: string;
  isButton?: boolean;
  isHrefExternal?: boolean;
  onClick?: () => void;
};

export const MobileCTA = ({ ctaTitle, href, isHrefExternal, onClick }: MobileCtaProps) => {
  if (!ctaTitle) return null;
  if (!href && !onClick) return null;

  return (
    <LinkButton
      className="mt-10 block w-full md:hidden"
      variant="primary"
      size="large"
      data-testid="carousel-link"
      href={href}
      isExternal={isHrefExternal}
      target="_self"
      onClick={onClick}
    >
      {ctaTitle}
    </LinkButton>
  );
};
