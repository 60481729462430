import { cn } from '@virginexperiencedays/tailwind';
import { useAspectRatioSupported } from '@virginexperiencedays/hooks';

import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { DisplayHeading, type DisplayHeadingProps } from '../../typography/DisplayHeading';
import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  ResponsiveAspectRatio,
} from '../../utils/mapAspectRatio';
import { VisualHeading, type VisualHeadingProps } from '../../typography/VisualHeading';

export type DisplayCardProps = {
  className?: string;
  /** Title of the card */
  title: string;
  /** SEO Override heading size */
  titleAs?: VisualHeadingProps['as'];
  /** Subtitle displayed above title */
  heroTitle: string;
  /** SEO Override heading size */
  heroTitleAs?: DisplayHeadingProps['as'];
  /** Text displayed in cta */
  ctaText?: string;
  /** Url used in cta */
  ctaUrl?: string;
  /** Image src to be displayed in the card */
  src: string;
  /** alt of the image */
  alt?: string;
  /** Href of the card */
  href: string;
  /** Whether to force external link behavior for href */
  isHrefExternal?: boolean;
  /** set the aspect ratio of the image */
  aspectRatio?: ResponsiveAspectRatio;
  size?: 'regular' | 'large';
  /** Whether to force priority image preloading for LCP benefit */
  priority: boolean;
  /** Tracking, etc */
  onClick?: (e: any) => void;
};

export const DisplayCard = ({
  className = null,
  title,
  titleAs,
  heroTitle,
  heroTitleAs,
  ctaText,
  src,
  alt,
  href,
  isHrefExternal,
  aspectRatio = '16/9',
  size = 'regular',
  priority,
  onClick = () => {},
}: DisplayCardProps) => {
  const { isSupported } = useAspectRatioSupported();

  return (
    <div data-testid="display-card" className={cn('group relative overflow-hidden', className)}>
      <LinkWrap
        className={cn(
          'relative flex flex-col items-start justify-between overflow-hidden rounded no-underline',
          'after:absolute after:inset-0 after:bottom-1/2 after:bg-gradient-to-b after:from-black after:to-transparent after:opacity-50',
          isSupported && mapAspectRatio(aspectRatio),
          !isSupported && mapAspectRatioPolyfill(aspectRatio)
        )}
        href={href}
        isExternal={isHrefExternal}
        dataTestId="display-card-image-link"
        onClick={onClick}
      >
        <Image
          className={cn(
            'image:object-cover absolute inset-0 h-full w-full transition-transform group-hover:scale-105',
            isSupported && mapAspectRatio(aspectRatio),
            !isSupported && mapAspectRatioPolyfill(aspectRatio)
          )}
          src={src}
          dataTestId="display-card-image"
          alt={alt ?? heroTitle}
          priority={priority}
          fill
        />
        <div className="absolute top-0 z-[2] px-4 py-6 text-white">
          <DisplayHeading
            size="5"
            leading="none"
            className="font-normal"
            as={heroTitleAs}
            data-testid="display-card-heroTitle"
          >
            {heroTitle}
          </DisplayHeading>
          <VisualHeading
            size="5"
            leading="none"
            className={cn(
              'mt-1 font-normal tracking-normal drop-shadow',
              size === 'large' && 'text-3xl'
            )}
            as={titleAs}
            data-testid="display-card-title"
          >
            {title}
          </VisualHeading>
        </div>
        {ctaText ? (
          <div
            className={cn(
              'absolute bottom-0 mx-4 my-2 my-6 inline-block rounded px-4 py-2 transition-colors',
              'text-grey-800 hover:text-link bg-white text-sm font-semibold leading-none no-underline'
            )}
            data-testid="display-card-cta"
          >
            {ctaText}
          </div>
        ) : null}
      </LinkWrap>
    </div>
  );
};
