import type { MouseEvent } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';

export type HeroStyle05Props = {
  className?: string;
  dataTestId?: string;
  title: string;
  body: string;
  ctaLabel?: string;
  ctaLink?: string;
  bgImage: { desktop: { url: string; alt: string }; tablet: { url: string; alt: string } };
  images: { id: string; url: string; alt: string }[];
  imageIsPriority?: boolean;
  defaultBgImage: string;
  maskImages: {
    mobile: string;
    tablet: string;
    desktop: string;
  };
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

const cnBgImage = 'hidden absolute top-0 left-0 z-0 w-[1280px] h-[200px]';

export const HeroStyle05 = ({
  className,
  dataTestId = 'hero-style-05',
  title,
  body,
  ctaLabel,
  ctaLink,
  bgImage: patternImage,
  images,
  imageIsPriority,
  defaultBgImage,
  maskImages,
  onClick,
}: HeroStyle05Props) => {
  const hasContent = !!title || !!body || !!ctaLabel;

  // must have all 3 images
  const hasImage = !!images?.[0]?.url && !!images?.[1]?.url && !!images?.[2]?.url;

  if (!hasContent && !hasImage) return null;

  const bgImageDesktop = patternImage?.desktop?.url ?? patternImage?.tablet?.url ?? defaultBgImage;
  const bgImageTablet = patternImage?.tablet?.url ?? patternImage?.desktop?.url ?? defaultBgImage;

  const heroProps = {
    'data-testid': dataTestId,
    className: cn(
      'bg-background-page relative flex min-h-[200px] flex-col items-center gap-4',
      'text-neutral w-full overflow-hidden p-0 no-underline',
      'md:flex-row md:rounded-lg md:bg-white md:px-6 md:py-4',
      'xl:px-14',
      ctaLink ? 'cursor-pointer' : 'cursor-default',
      className
    ),
  };
  const componentProps = {
    bgImageDesktop,
    bgImageTablet,
    body,
    cnBgImage,
    ctaLabel,
    ctaLink,
    dataTestId,
    hasContent,
    hasImage,
    imageIsPriority,
    images,
    maskImages,
    title,
  };

  if (ctaLink) {
    return (
      <LinkWrap {...heroProps} href={ctaLink} onClick={onClick}>
        <Component {...componentProps} />
      </LinkWrap>
    );
  }

  return (
    <div {...heroProps}>
      <Component {...componentProps} />
    </div>
  );
};

type ComponentProps = Pick<
  HeroStyle05Props,
  | 'body'
  | 'ctaLabel'
  | 'ctaLink'
  | 'dataTestId'
  | 'imageIsPriority'
  | 'images'
  | 'maskImages'
  | 'title'
> & {
  bgImageDesktop: string;
  bgImageTablet: string;
  hasImage: boolean;
  hasContent: boolean;
};

const Component = ({
  bgImageDesktop,
  bgImageTablet,
  body,
  ctaLabel,
  ctaLink,
  dataTestId,
  hasContent,
  hasImage,
  imageIsPriority,
  images,
  maskImages,
  title,
}: ComponentProps) => (
  <>
    <Image
      dataTestId={`${dataTestId}-bg-image-desktop`}
      className={cn(cnBgImage, 'md:hidden xl:block')}
      src={bgImageDesktop}
      alt="bg desktop"
      priority={imageIsPriority}
      fill
    />
    <Image
      dataTestId={`${dataTestId}-bg-image-tablet`}
      className={cn(cnBgImage, 'md:block xl:hidden')}
      src={bgImageTablet}
      alt="bg tablet"
      priority={imageIsPriority}
      fill
    />
    {hasImage && (
      <MaskedImagesResponsive
        dataTestId={dataTestId}
        title={title}
        maskImages={maskImages}
        images={images}
        imageIsPriority={imageIsPriority}
      />
    )}
    {hasContent && (
      <div className="items-normal relative z-[1] flex w-full flex-col justify-between gap-6 md:flex-row md:items-center">
        <div
          className={cn(
            'mx-auto flex w-full max-w-[475px] flex-col gap-1 text-center',
            'md:m-0 md:w-[40%] md:text-left lg:w-[47%] xl:w-[40%]'
          )}
        >
          {title && (
            <h2
              data-testid={`${dataTestId}-title`}
              className={cn(
                'font-inter m-0 text-balance text-2xl font-semibold leading-tight -tracking-[0.48px]',
                'text-neutral-strong md:text-grey-800',
                'lg:text-[28px] lg:-tracking-[0.56px]'
              )}
            >
              {title}
            </h2>
          )}
          {body && (
            <p
              data-testid={`${dataTestId}-body`}
              className={cn(
                'font-inter text-neutral m-0 text-base font-[500]',
                'text-balance leading-normal',
                'md:text-grey-700 lg:text-xl/normal lg:font-normal'
              )}
            >
              {body}
            </p>
          )}
        </div>
        {ctaLink && (
          <span
            data-testid={`${dataTestId}-cta`}
            className={cn(
              'bg-background-primary block rounded p-4 text-center font-normal font-semibold leading-none text-white',
              'md:inline-block md:max-w-[300px] md:bg-white md:px-6 md:py-4 md:shadow-[0px_20px_24px_-5px_rgba(0,_0,_0,_0.2),_0px_10px_10px_-5px_rgba(0,_0,_0,_0.12)]',
              'md:text-grey-700 md:hover:text-link md:transition md:duration-100 md:ease-linear'
            )}
          >
            {ctaLabel || 'Shop Now'}
          </span>
        )}
      </div>
    )}
  </>
);

type MaskedImagesProps = Pick<
  HeroStyle05Props,
  'dataTestId' | 'title' | 'maskImages' | 'images' | 'imageIsPriority'
>;

// NOTE: since WebkitMaskImage is not supported in tailwind, we can only use via inline style which we can only set 1 at a time.
// For this component, mobile, tablet, desktop has different mask image.
const MaskedImagesResponsive = (props: MaskedImagesProps) => {
  return (
    <>
      <MaskedImages {...props} breakpoint="mobile" />
      <MaskedImages {...props} breakpoint="tablet" />
      <MaskedImages {...props} breakpoint="desktop" />
    </>
  );
};

const MaskedImages = ({
  dataTestId,
  title,
  maskImages,
  images,
  imageIsPriority,
  breakpoint,
}: MaskedImagesProps & { breakpoint: 'mobile' | 'tablet' | 'desktop' }) => {
  const maskProperties = {
    mobile: {
      WebkitMaskImage: `url(${maskImages.mobile})`,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'top center',
    },
    tablet: {
      WebkitMaskImage: `url(${maskImages.tablet})`,
      WebkitMaskSize: '100%',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'top center',
    },
    desktop: {
      WebkitMaskImage: `url(${maskImages.desktop})`,
      WebkitMaskSize: '100%',
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskPosition: 'top center',
    },
  };
  const displayProperties = {
    mobile: 'flex md:hidden xl:hidden',
    tablet: 'hidden md:flex xl:hidden',
    desktop: 'hidden md:hidden xl:flex',
  };
  return (
    <div
      data-testid={`${dataTestId}-image`}
      style={maskProperties[breakpoint]}
      className={cn(
        'flex h-[180px] w-full max-w-full flex-row gap-2',
        'sm:h-[280px]',
        'md:absolute md:right-0 md:top-0 md:z-0 md:h-[220px] md:w-[58%] md:max-w-[480px] md:bg-white',
        'xl:h-[265px] xl:max-w-[740px] xl:gap-4',
        displayProperties[breakpoint]
      )}
    >
      {images.map(({ id, url, alt }) => (
        <Image
          key={id}
          className="image:object-cover image:object-center h-full w-full"
          src={url}
          alt={alt ?? title}
          priority={imageIsPriority}
          fill
        />
      ))}
    </div>
  );
};
