import { cn } from '@virginexperiencedays/tailwind';
import {
  NuggetModule,
  type NuggetModuleItemProps,
} from '@virginexperiencedays/components-v2/src/navigation/NuggetModule';
import {
  DisplayHeading,
  type DisplayHeadingProps,
} from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { useDetectNewURL } from '../../../../utils/routing/useDetectNewURL';

type CategoryNuggetsProps = {
  navigationItems: NuggetModuleItemProps[];
  hasTitle?: boolean;
  isWrapped?: boolean;
  title?: string;
  titleLvl?: DisplayHeadingProps['as'];
  description?: string;
  onClick?: (href: string, data?: any) => void;
  /**
   * Enables behavior to detect whether we're on the new URL scheme (e.g.
   * /c/driving) or the old URL scheme (e.g. /driving) and transforms
   * navigationItems accordingly.
   *
   * Defaults to true.
   */
  detectNewURL?: boolean;
};

/*
 * Function to support both  legacy/new urls based on `isNewURL`
 */

const transformNavigationItems = ({ navigationItems, isNewURL, detectNewURL }) => {
  // guard: only transform if detectNewURL is true
  if (!detectNewURL) return navigationItems;

  // case: we're on a new URL, no need to transform
  if (isNewURL) return navigationItems;

  // case: we're on an old URL. take the last slug
  return navigationItems.map((navigationItem) => {
    const parts = navigationItem.href.split('/');
    return {
      ...navigationItem,
      href: `/${parts[parts.length - 1]}`,
    };
  });
};

export const CategoryNuggets = ({
  hasTitle = false,
  isWrapped = false,
  navigationItems,
  title,
  titleLvl,
  description,
  detectNewURL = true,
  onClick = null,
}: CategoryNuggetsProps) => {
  const { isNewURL } = useDetectNewURL();

  if (!Array.isArray(navigationItems) || !navigationItems.length) return null;

  return (
    <div data-testid="category-nuggets">
      <div className="mb-6">
        {hasTitle && (
          <StyledHeading description={description} as={titleLvl ?? 'h2'}>
            {title || 'Other categories you might like:'}
          </StyledHeading>
        )}
        {description && (
          <div className="leading-normal text-neutral [word-break:break-word]">{description}</div>
        )}
      </div>
      <NuggetModule
        navigationItems={transformNavigationItems({
          navigationItems,
          isNewURL,
          detectNewURL,
        })}
        isWrapped={isWrapped}
        onClick={onClick}
      />
    </div>
  );
};

type StyledHeadingProps = DisplayHeadingProps & {
  description?: string;
};

const StyledHeading = ({ children, description, ...rest }: StyledHeadingProps) => (
  <DisplayHeading
    className={cn('text-xl font-bold leading-snug text-neutral-strong', !!description && 'mb-2')}
    {...rest}
  >
    {children}
  </DisplayHeading>
);
