import { BasicCard } from '@virginexperiencedays/components-v2/src/cards/Basic';
import { CarouselProductCard as ProductCardV1 } from '@virginexperiencedays/components-v2/src/cards/CarouselProduct';
import {
  ProductCardV2,
  type ProductCardV2Props,
} from '@virginexperiencedays/components-v2/src/cards/ProductV2';
import { CarouselEndCard } from '@virginexperiencedays/components-v2/src/cards/CarouselEndCard';
import { VisualHeadingProps } from '@virginexperiencedays/components-v2/src/typography/VisualHeading';
import { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';

import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
import { createLinkedPath } from '@virginexperiencedays/header-2024/src/utils/cms/createLinkedPath';

import { LCP_PREFETCH_CAROUSEL_COUNT } from '../constants';
import {
  CardVariant,
  CategoryLinkType,
  CategoryLinksType,
  CustomCardsType,
  LayoutStructureType,
  ProductCardsType,
  TTDBlogPostLinksType,
  AlgoliaCardsType,
  CarouselEndCardType,
} from '../types/slices';
import { CardsFactory } from './CardsFactory-v2';
import { getIsNew } from './getIsNew';

import type { ComponentType } from 'react';
import type { CarouselProductCardProps } from '@virginexperiencedays/components-v2/src/cards/CarouselProduct/types';
import type { ResponsiveAspectRatio } from '@virginexperiencedays/components-v2/src/utils/mapAspectRatio';

export const typeOptions = (obj: any, variation: CardVariant) => {
  switch (variation) {
    case 'cardsProductIDs':
      return obj as ProductCardsType;
    case 'cardsCategoryLinks':
      return obj as CategoryLinksType;
    case 'cardsPostLinks':
      return obj as TTDBlogPostLinksType;
    case 'cardsCustomContent':
      return obj as CustomCardsType;
    case 'cardsProductsAlgolia':
      return obj as AlgoliaCardsType;
  }
};

/**
 * Reason being is this Variants Factory should be reused for Carousel and columns and any other layout types controlled via CMS
 */

type CardVariantsFactoryProps = {
  Wrapper: ComponentType;
  wrapperProps: any;
  options: LayoutStructureType;
  endCardData?: CarouselEndCardType;
  // Determines whether images should be prioritised for LCP preloading
  priority?: boolean;
  // Flag for Product Card Split Testing via NEXT_PUBLIC_FF_PRODUCT_CARDS_V2
  isProductCardsV2?: boolean;
  // Flag for Product Card V2 whether to show with Image Carousel. Should be disabled if also wrapped with Carousels (e.g. CarouselStructure, CarouselWithIntro, CarouselWithTabs)
  isProductCardWithImageCarousel?: boolean;
  // Product Card V2 title class
  titleClassName?: string;
};

export const CardVariantsFactory = ({
  Wrapper,
  wrapperProps,
  options,
  priority,
  endCardData,
  titleClassName,
  isProductCardsV2,
  isProductCardWithImageCarousel,
}: CardVariantsFactoryProps) => {
  const { variation, items, primary } = options;

  if (!items?.length) {
    return null;
  }
  // This aspect ratio can be extracted per slice, if more custom behaviour will be required
  const aspectRatioObj = {
    mobile: primary.mobile_aspect_ratio ?? '16/9',
    tablet: primary.tablet_aspect_ratio,
  } as ResponsiveAspectRatio;

  // factory-level defaults. Hook it up per slice to give user control
  const cardTitleAs = (primary.card_title_as ?? 'h2') as VisualHeadingProps['as'];
  const cardHeroTitleAs = (primary.card_hero_title_as ?? 'div') as DisplayHeadingProps['as'];

  switch (variation) {
    case 'cardsPostLinks': {
      return (
        <Wrapper {...wrapperProps}>
          {items
            .filter((item) => item.content_link?.data?.uid)
            .map((item, index) => (
              <BasicCard
                key={index}
                title={
                  item.title_override ??
                  item.content_link?.data?.display_title_override ??
                  item.content_link?.data?.title
                }
                titleAs={cardTitleAs}
                href={createLinkedPath(item.content_link.type, item.content_link?.data?.uid)}
                isHrefExternal={item.is_url_external}
                src={
                  item.image_override?.url
                    ? item.image_override.url
                    : item.content_link?.data?.coverimage?.Thumbnail?.url ??
                      item.content_link?.data?.og_image?.url
                }
                description={item.description_override ?? item.content_link?.data?.og_description}
                aspectRatio={aspectRatioObj}
                ctaText={item.cta_text} // do we need fallback here to something like Read More?
                onClick={item.onClick}
                priority={priority && index <= LCP_PREFETCH_CAROUSEL_COUNT}
              />
            ))}
        </Wrapper>
      );
    }
    case 'cardsProductIDs': {
      let renderCardsProductItems = items
        .filter((item) => item.status === 'active')
        .map((item, index) => (
          /**
           * NOTE: CardsFactory is not used here because it maps a
           * fundamentally different entity (CategoryLinkType vs Product).
           *
           * To that note, conditionals are used to handle card_type ===
           * 'bf_minimal_product_card'. If this use case grows, it might be
           * best to create a wholly different ProductCardsFactory instead
           * of overloading CardsFactory.
           */
          <CarouselProductCard
            key={item.id}
            index={index}
            priority={priority}
            item={item}
            titleClassName={titleClassName}
            isProductCardsV2={isProductCardsV2}
            isProductCardWithImageCarousel={isProductCardWithImageCarousel}
          />
        ));

      if (endCardData?.displayEndCard) {
        renderCardsProductItems = [
          ...renderCardsProductItems,
          <CarouselEndCard
            title={endCardData.endCardTitle}
            overTitle={endCardData.endCardOverTitle}
            ctaLink={endCardData.endCardCtaLink}
            ctaTitle={endCardData.endCardCtaTitle}
            trackCtaLink={endCardData.endCardTrackCtaLink}
            trackOnReached={endCardData.endCardTrackOnReached}
          />,
        ];
      }
      return <Wrapper {...wrapperProps}>{renderCardsProductItems}</Wrapper>;
    }
    case 'cardsCategoryLinks': {
      let renderCategoryLinksItems = items
        .filter((item) => item.content_link?.data?.uid)
        .map((item, index) => (
          <CardsFactory
            key={index}
            aspectRatioObj={aspectRatioObj}
            item={item}
            card_type={primary.card_type}
            card_title_as={cardTitleAs}
            card_hero_title_as={cardHeroTitleAs}
            onClick={item.onClick}
            priority={priority && index <= LCP_PREFETCH_CAROUSEL_COUNT}
          />
        ));
      if (endCardData?.displayEndCard) {
        const endCardAspectRatioObj = {
          mobile: primary.mobile_aspect_ratio
            ? primary.mobile_aspect_ratio
            : primary.card_type === 'display_card'
            ? '16/9'
            : 'auto',
          tablet: primary.tablet_aspect_ratio,
        } as ResponsiveAspectRatio;

        renderCategoryLinksItems = [
          ...renderCategoryLinksItems,
          <CarouselEndCard
            title={endCardData.endCardTitle}
            overTitle={endCardData.endCardOverTitle}
            ctaLink={endCardData.endCardCtaLink}
            ctaTitle={endCardData.endCardCtaTitle}
            trackCtaLink={endCardData.endCardTrackCtaLink}
            trackOnReached={endCardData.endCardTrackOnReached}
            aspectRatioObj={endCardAspectRatioObj}
          />,
        ];
      }
      return <Wrapper {...wrapperProps}>{renderCategoryLinksItems}</Wrapper>;
    }
    case 'cardsCustomContent': {
      return (
        <Wrapper {...wrapperProps}>
          {items
            .filter((item) => item.url && item.hero_title)
            .map((item, index) => {
              const mappedItem: CategoryLinkType = {
                hero_title: item.hero_title,
                title_override: item.title,
                cta_text: item.cta_text,
                description_override: item.description,
                image_override: item.image,
                content_link: getPrismicLinkPath(item.url)
                  ? ({
                      data: {
                        uid: getPrismicLinkPath(item.url),
                      },
                    } as CategoryLinkType['content_link'])
                  : null,
              };

              return (
                <CardsFactory
                  key={index}
                  aspectRatioObj={aspectRatioObj}
                  item={mappedItem}
                  card_type={primary.card_type}
                  card_title_as={cardTitleAs}
                  card_hero_title_as={cardHeroTitleAs}
                  onClick={item.onClick}
                  priority={priority && index <= LCP_PREFETCH_CAROUSEL_COUNT}
                />
              );
            })}
        </Wrapper>
      );
    }
    case 'cardsProductsAlgolia': {
      let renderProductAlgoliaItems = items
        .filter(({ slug, title }) => slug && title)
        .map((item, index) => {
          return (
            <CarouselProductCard
              key={item.id}
              index={index}
              priority={priority}
              item={item}
              titleClassName={titleClassName}
              isProductCardsV2={isProductCardsV2}
              isProductCardWithImageCarousel={isProductCardWithImageCarousel}
            />
          );
        });

      if (endCardData?.displayEndCard) {
        renderProductAlgoliaItems = [
          ...renderProductAlgoliaItems,
          <CarouselEndCard
            title={endCardData.endCardTitle}
            overTitle={endCardData.endCardOverTitle}
            ctaLink={endCardData.endCardCtaLink}
            ctaTitle={endCardData.endCardCtaTitle}
            trackCtaLink={endCardData.endCardTrackCtaLink}
            trackOnReached={endCardData.endCardTrackOnReached}
          />,
        ];
      }
      return <Wrapper {...wrapperProps}>{renderProductAlgoliaItems}</Wrapper>;
    }
    default:
      return null;
  }
};

// As much as possible, mirror web/components/pages/search/ProductCard@ProductGridCard
export const CarouselProductCard = ({
  index,
  priority,
  item,
  titleClassName,
  isProductCardsV2,
  isProductCardWithImageCarousel,
}: {
  index: number;
  priority: boolean;
  item: any;
  titleClassName?: CardVariantsFactoryProps['titleClassName'];
  isProductCardsV2?: CardVariantsFactoryProps['isProductCardsV2'];
  isProductCardWithImageCarousel?: CardVariantsFactoryProps['isProductCardWithImageCarousel'];
}) => {
  const isPriority = priority && index <= LCP_PREFETCH_CAROUSEL_COUNT;
  // Product Algolia will have isNew prop, Product Card by ID will not, so have to calculate from item.dateActivated
  const isNew = item?.isNew || getIsNew(item.dateActivated);

  if (isProductCardsV2) {
    return (
      <ProductCardV2
        // includes tracking from web/components/pages/search/tracking/clicks.ts@products
        {...(item as ProductCardV2Props)}
        titleClassName={titleClassName}
        percentOff={item?.price?.percentOff}
        displayPrice={item?.price?.displayPrice}
        rrp={item.price.rrp}
        averageRating={item.reviews.averageRating}
        images={[item.media.mainImage, ...(item.media.images || [])].map((image, i) => ({
          src: image.url,
          alt: image.altText || `${item.title} ${i + 1}`,
        }))}
        priority={isPriority}
        isImageCarousel={isProductCardWithImageCarousel}
      />
    );
  }

  return (
    <ProductCardV1
      {...(item as CarouselProductCardProps)}
      alt={item.title}
      averageRating={item.reviews?.averageRating}
      displayPrice={item.price?.displayPrice}
      isHrefExternal={item.is_url_external}
      isNew={isNew}
      percentOff={item.price?.percentOff ?? null}
      priority={isPriority}
      rrp={item.price?.displayPrice !== item.price?.rrp ? item.price?.rrp : null}
      src={item.media?.mainImage?.url}
      totalReviews={item.reviews?.count}
    />
  );
};
