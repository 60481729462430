import { ReactNode } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { LinkWrap } from '../LinkWrap';

export type NuggetProps = {
  testId?: string;
  className?: string;
  onClick?: () => void;
  asLink?: boolean;
  href?: string;
  isHrefExternal?: boolean;
  prefetch?: boolean;
  children: ReactNode;
  as?: 'span' | 'li';
};

export const Nugget = ({
  className,
  onClick,
  asLink,
  href,
  isHrefExternal,
  prefetch,
  children,
  testId = 'nugget',
  as = 'span',
}: NuggetProps) => {
  const Tag = as;
  if (asLink && !href) return null;

  if (!asLink) {
    return (
      <Tag className={cn(styles.base, className)} data-testid={testId} onClick={onClick}>
        {children}
      </Tag>
    );
  }

  return (
    <LinkWrap
      href={href}
      className={cn(
        styles.base,
        'hover:bg-background-primary-faded-hover focus:bg-background-primary-faded',
        className
      )}
      isExternal={isHrefExternal}
      prefetch={prefetch}
      target="_self"
      onClick={onClick}
      dataTestId={testId}
    >
      {children}
    </LinkWrap>
  );
};

const styles = {
  base: 'font-inter bg-background-primary-faded text-neutral text-sm leading-none font-semibold no-underline',
};
