import { useRef } from 'react';

import { useInView } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { LinkButton } from '../../navigation/LinkButton';
import { BodyText } from '../../typography/BodyText';
import { DisplayHeading } from '../../typography/DisplayHeading';
import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  type ResponsiveAspectRatio,
} from '../..//utils/mapAspectRatio';

type CarouselEndCardProps = {
  title?: string;
  overTitle?: string;
  ctaLink?: string;
  trackCtaLink?: () => void;
  trackOnReached?: () => void;
  ctaTitle?: string;
  aspectRatioObj?: ResponsiveAspectRatio;
};

export const CarouselEndCard = ({
  title,
  overTitle,
  ctaLink,
  trackCtaLink,
  trackOnReached,
  ctaTitle,
  aspectRatioObj,
}: CarouselEndCardProps) => {
  const firedOnce = useRef(false);
  const endCardRef = useRef(null);

  const intersectionCallback = (isIntersecting: boolean) => {
    if (trackOnReached && isIntersecting && !firedOnce?.current) {
      trackOnReached();
      firedOnce.current = true;
    }
  };

  useInView({
    elementRef: endCardRef,
    options: { threshold: 0.9 },
    callbackFn: intersectionCallback,
  });

  if (!title) return null;

  return (
    <div
      className={cn(
        'bg-background-elevation-raised border-border-neutral flex h-full flex-col justify-center overflow-hidden rounded-lg border p-6',
        aspectRatioObj && mapAspectRatio(aspectRatioObj),
        aspectRatioObj && mapAspectRatioPolyfill(aspectRatioObj)
      )}
      ref={endCardRef}
    >
      <BodyText
        size="small"
        leading="none"
        className="text-brand mb-2 font-semibold uppercase tracking-[0.04em]"
      >
        {overTitle}
      </BodyText>
      <DisplayHeading size="2" className="mb-6 md:mb-0">
        {title}
      </DisplayHeading>
      {!!ctaLink && !!ctaTitle && (
        <LinkButton
          href={ctaLink}
          onClick={trackCtaLink}
          variant="primary"
          className="bg-background-primary mr-auto mt-6 inline-block border-0 px-4 py-[12px]"
        >
          {ctaTitle}
        </LinkButton>
      )}
    </div>
  );
};

export default CarouselEndCard;
