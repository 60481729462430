import { PromotionBadge, PROMOTION_BADGE_MAP } from '../../../PromotionBadge';

type Props = {
  className?: string;
  homeLink?: string;
};

export const LogoBlackFriday = ({ className, homeLink = '' }: Props) => (
  <PromotionBadge
    className={className}
    type="black-friday-best-deals-ever"
    homeLink={homeLink}
    priority
    style={{
      width: `${PROMOTION_BADGE_MAP['black-friday-best-deals-ever'].width}px`,
      height: `${PROMOTION_BADGE_MAP['black-friday-best-deals-ever'].height}px`,
    }}
  />
);
