import type { MouseEvent } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { VisualHeading } from '../../typography/VisualHeading';
import { BodyText } from '../../typography/BodyText';

import { Overtitle } from './Overtitle';

export type HeroStyle02Props = {
  title: string;
  body: string;
  ctaLabel: string;
  link: string;
  isLinkExternal: boolean;
  className?: string;
  dataTestId?: string;
  overTitle?: string;
  imageUrl?: string;
  imageAlt?: string;
  mobileImageUrl?: string;
  mobileImageAlt?: string;
  imageIsPriority?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
};

export const HeroStyle02 = ({
  className,
  dataTestId = 'hero-style-02',
  overTitle,
  title,
  body,
  ctaLabel = 'Shop Now',
  link,
  isLinkExternal,
  imageUrl,
  imageAlt,
  mobileImageUrl,
  mobileImageAlt,
  imageIsPriority,
  onClick,
}: HeroStyle02Props) => {
  if (!imageUrl && !mobileImageUrl) return null;

  return (
    <LinkWrap
      className={cn(
        'text-neutral grid grid-cols-1 items-center gap-6 md:grid-cols-2 md:gap-10',
        className
      )}
      dataTestId={dataTestId}
      href={link}
      isExternal={isLinkExternal}
      onClick={onClick}
    >
      <div className="p-0 text-center md:p-6 md:pl-0 md:text-left">
        <div className="mx-auto max-w-[480px] md:m-[unset]">
          {!!overTitle && <Overtitle className="mb-1">{overTitle}</Overtitle>}
          {!!title && (
            <VisualHeading
              as="h2"
              className="text-balance text-4xl font-normal leading-none tracking-tight sm:text-4xl md:text-5xl lg:text-[3.5rem] xl:text-[4rem]"
            >
              {title}
            </VisualHeading>
          )}
          {!!body && (
            <BodyText className="mt-4 block text-balance text-base leading-normal sm:text-xl sm:leading-normal">
              {body}
            </BodyText>
          )}
          {!!ctaLabel && (
            <BodyText
              className={cn(
                'bg-background-primary mb-6 mt-4 inline-block w-full rounded border-2 border-transparent px-10 py-3.5 transition-all',
                'select-none text-center text-base font-semibold leading-none text-white',
                'hover:bg-background-primary-highlight focus:bg-background-primary',
                'md:mt-10 md:w-auto'
              )}
            >
              {ctaLabel}
            </BodyText>
          )}
        </div>
      </div>
      <div className="relative -order-1 self-start overflow-hidden rounded-lg md:order-1 md:order-none md:max-h-[unset] md:rounded-none md:[&_img]:rounded-lg">
        {!!mobileImageUrl && (
          <Image
            dataTestId="hero-style02-image-mobile"
            className={cn(styles.imageWrapper, 'block', imageUrl ? 'md:hidden' : 'md:block')}
            imageClassName={styles.image}
            priority={imageIsPriority}
            src={mobileImageUrl}
            alt={mobileImageAlt ?? imageAlt ?? title}
            width={1220}
            height={800}
          />
        )}
        {!!imageUrl && (
          <Image
            dataTestId="hero-style02-image-main"
            className={cn(
              styles.imageWrapper,
              mobileImageUrl ? 'hidden' : 'block',
              'aspect-[3/2] md:block'
            )}
            imageClassName={styles.image}
            priority={imageIsPriority}
            src={imageUrl}
            alt={imageAlt ?? title}
            fill
          />
        )}
      </div>
    </LinkWrap>
  );
};

const styles = {
  imageWrapper:
    'relative h-[200px] [&>span]:max-h-[200px] [&>span]:w-full md:h-auto md:max-h-[unset] md:[&>span]:max-h-[unset]',
  image: 'object-cover object-center h-[200px] md:h-auto',
};
