import { useCallback } from 'react';
// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { HeroStyle03 as Component } from '@virginexperiencedays/components-v2/src/banners/HeroStyle/Style3';
// Hooks
import { useGiftFinderContext } from '@virginexperiencedays/components-v2/src/drawers/GiftFinder/context/GiftFinderContext';
// Utils
import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
// Types
import type { ImageField } from '@prismicio/types';
import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import type { PrismicContext } from '../../types/context';

type HeroStyle03Props = {
  slice: {
    primary: {
      title: string;
      overtitle?: string;
      body: string;
      image: ImageField;
      cta_text?: string;
      cta_link?: any;
      mobile_image?: ImageField;
      desktop_image?: ImageField;
      image_above_fold?: boolean;
      cta_open_finder: boolean;
    };
    slice_type: string;
    position?: number;
  };
  context: PrismicContext;
};

const HeroStyle03 = ({ slice, context }: HeroStyle03Props) => {
  const {
    primary: {
      overtitle,
      title,
      body,
      cta_text,
      cta_link,
      mobile_image,
      desktop_image,
      image_above_fold,
      cta_open_finder,
    },
    slice_type,
    position,
  } = slice;
  const { lastSlug, isFaceted, pageType, trackSlice } = context;
  const { handleOpen } = useGiftFinderContext();

  const link = getPrismicLinkPath(cta_link) || '#';
  const isLinkExternal = cta_link.link_type !== 'Document';

  const lcpPriority = position <= LCP_PREFETCH_SLICE_COUNT;

  // required as Primsic's initial default value is always null
  const imageIsPriority = lcpPriority || image_above_fold === null || !!image_above_fold;
  const ctaOpenFinder = cta_open_finder === null || !!cta_open_finder;

  const handleTracking = useCallback(() => {
    trackSlice?.({
      slug: lastSlug,
      label: link,
      sliceType: slice_type,
      pageType,
    });
  }, [link, lastSlug, slice_type, pageType]);

  if (isFaceted) return null;

  const handleClick = (e) => {
    if (ctaOpenFinder && handleOpen) {
      e.preventDefault();
      handleOpen('banner');
    } else {
      // let original event propagate
      handleTracking();
    }
  };

  return (
    <Container>
      <Component
        overTitle={overtitle}
        title={title}
        body={body}
        imageIsPriority={imageIsPriority}
        image={{ mobile: mobile_image, desktop: desktop_image }}
        ctaLabel={cta_text || 'Shop Now'}
        link={link}
        isLinkExternal={isLinkExternal}
        onClick={handleClick}
      />
    </Container>
  );
};

export default HeroStyle03;
