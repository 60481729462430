// Components
import { CMSRichText, type CMSRichTextProps } from '../../utils/CMSRichText';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
// Types
import type * as prismicT from '@prismicio/types';
import type { PrismicContext } from '../../types/context';

type RichTextProps = {
  slice: {
    primary: {
      content: prismicT.RichTextField;
      text_alignment: string;
      scale: string;
      max_width: string;
      position: string;
    };
  };
  context: PrismicContext;
};

const RichText = ({ slice, context }: RichTextProps) => {
  const {
    primary: { content, text_alignment, scale, max_width, position },
  } = slice;
  const { hide, isFaceted } = context;

  if (isFaceted || hide) {
    return null;
  }

  return (
    <Container>
      <CMSRichText
        content={content}
        textAlign={text_alignment as CMSRichTextProps['textAlign']}
        scale={scale}
        maxWidth={max_width}
        position={position}
      />
    </Container>
  );
};

export default RichText;
