import { indexNames } from '@virginexperiencedays/search/constants';
import { useInstantSearch } from 'react-instantsearch-core';
import { getAttributeType } from '../getAttributeType';
import type { AlgoliaAttribute } from '../stateToRoute';

export const useCurrentCategoryItems = ({ attribute }: { attribute: AlgoliaAttribute }) => {
  const attributeType = getAttributeType(attribute);
  const indexName = indexNames.DEFAULT;

  const { renderState } = useInstantSearch();
  const valuesAtLabel = renderState?.[indexName]?.[attributeType]?.[attribute];

  return { items: valuesAtLabel?.items || [] };
};
