import { cn } from '@virginexperiencedays/tailwind';

import { LinkWrap } from '../../navigation/LinkWrap';
import { BodyText } from '../../typography/BodyText';
import { DisplayHeading, DisplayHeadingProps } from '../../typography/DisplayHeading';
import type { ResponsiveAspectRatio } from '../../utils/mapAspectRatio';

import { MediaCard } from '../Media';

export type BasicCardProps = {
  /** title for the card */
  title: string;
  /** SEO override for title */
  titleAs?: DisplayHeadingProps['as'];
  /** media URL */
  src: string;
  /** card href */
  href: string;
  /** Whether to force external link behavior for href */
  isHrefExternal?: boolean;
  className?: string;
  /** optional description */
  description?: string;
  /** optional CTA - text that is shown */
  ctaText?: string;
  /** Controls whether there's left + right padding. */
  padded?: boolean;
  /** set the aspect ratio of the image */
  aspectRatio?: ResponsiveAspectRatio;
  /** size of the card */
  size?: 'small' | 'normal';
  isMinimal?: boolean;
  /** Tracking, etc */
  onClick?: (e: any) => void;
  /** Whether to force priority image preloading for LCP benefit */
  priority?: boolean;
};

export const BasicCard = ({
  title,
  titleAs,
  src,
  href,
  isHrefExternal,
  className = null,
  description,
  ctaText,
  padded = false,
  aspectRatio,
  size = 'small',
  isMinimal,
  priority,
  onClick = () => {},
}: BasicCardProps) => {
  return (
    <div className="relative">
      <LinkWrap
        href={href}
        isExternal={isHrefExternal}
        className={cn(
          'group relative flex max-w-[640px] flex-col no-underline',
          padded && 'overflow-hidden rounded shadow-md',
          className
        )}
        dataTestId="basic-card"
        onClick={onClick}
      >
        <MediaCard
          dataTestId="basic-card-image"
          src={src}
          alt={title}
          aspectRatio={aspectRatio}
          rounded
          priority={priority}
        />
        <div
          className={cn('text-neutral-strong pt-4', padded && 'px-4 pb-6 pt-4')}
          data-testid="basic-card-content"
        >
          <DisplayHeading
            className={cn(
              'text-neutral-strong group-hover:text-link leading-normal transition-colors',
              size === 'normal' ? 'text-base' : 'text-sm'
            )}
            as={titleAs}
            data-testid="basic-card-title"
          >
            {title}
          </DisplayHeading>
          <BodyText
            className={cn(
              'text-neutral mt-2 line-clamp-5',
              size === 'normal' ? 'text-base' : 'text-sm',
              isMinimal ? 'none' : 'block'
            )}
            data-testid="basic-card-desc"
          >
            {description}
          </BodyText>
          {ctaText ? (
            <span
              className="text-link mt-auto inline-flex items-center gap-0.5 pt-4 text-sm font-semibold leading-none"
              data-testid="basic-card-cta"
            >
              {ctaText}
              <SVG />
            </span>
          ) : null}
        </div>
      </LinkWrap>
    </div>
  );
};

const SVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 6L12 10L8 14"
      stroke="#E10A0A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
