import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { MegaNuggets as Component } from '@virginexperiencedays/components-v2/src/navigation/MegaNuggets';
import type {
  ColumnType,
  ColumnsType,
} from '@virginexperiencedays/components-v2/src/utils/mapColumns';

import { getContentLinkTitle, getContentLinkUrl } from '../../utils/overrides';

import type { PrismicContext } from '../../types/context';

type Breakpoint = string | null;
type Item = {
  text: string;
  href: {
    id: string;
    slug: string;
    link: string;
    data?: { title?: string; title_override?: string };
  };
};
type Props = {
  slice: {
    primary: {
      mobile: Breakpoint;
      mobile_large: Breakpoint;
      tablet: Breakpoint;
      desktop: Breakpoint;
      desktop_large: Breakpoint;
    };
    items: Item[];
    slice_type: 'mega_nuggets';
    variation: 'default';
  };
  context: PrismicContext;
};

const MegaNuggets = ({ slice, context }: Props) => {
  const {
    primary: { mobile, mobile_large, tablet, desktop, desktop_large },
    items,
    slice_type,
    variation,
  } = slice;
  const { lastSlug: slug, isFaceted, pageType, trackSlice } = context;

  const onClick = (href: string, data: any) => {
    // let original event propagate

    trackSlice?.({
      slug,
      label: data?.name ?? href,
      sliceType: slice_type,
      variation,
      pageType,
    });
  };

  if (isFaceted) return null;

  const itemsToMap: Item[] = items.map(({ text, href }) => ({
    text: getContentLinkTitle(href, text),
    href: { ...href, link: getContentLinkUrl(href) },
  }));

  // fallbacks align with Prismic defaults
  const columns: ColumnsType = {
    mobile: Number(mobile ?? 1) as ColumnType,
    largeMobile: Number(mobile_large ?? 2) as ColumnType,
    tablet: Number(tablet ?? 2) as ColumnType,
    desktop: Number(desktop ?? 2) as ColumnType,
    wide: Number(desktop_large ?? 2) as ColumnType,
  };

  return (
    <Container>
      <Component columns={columns} items={itemsToMap} onClick={onClick} />
    </Container>
  );
};

export default MegaNuggets;
