import type { RichTextField } from '@prismicio/types';

import { Accordion } from '../layout/Accordion';
import { DisplayHeading } from '../typography/DisplayHeading';
import { RichTextWrap } from '../typography/RichTextWrap';
import { StyledRichText } from '../typography/RichTextWrap/StyledRichText';

type AccordionGroupProps = {
  title: string;
  items: ItemType[];
  className?: string;
};

type ItemType = { heading: string; content: RichTextField; is_open?: boolean };

export const AccordionGroup = ({ title, items, className }: AccordionGroupProps) => {
  return (
    <div className={className}>
      {!!title && (
        <DisplayHeading className="text-neutral-strong mb-2" as="h2" size="3">
          {title}
        </DisplayHeading>
      )}
      {items.length > 0 &&
        items.map(({ heading, content, is_open }, index) => {
          return (
            <Accordion key={`faq-${heading}${index}`} heading={heading} open={is_open}>
              <StyledRichText>
                <RichTextWrap field={content} />
              </StyledRichText>
            </Accordion>
          );
        })}
    </div>
  );
};
