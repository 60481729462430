import { ChangeEvent, FormEvent, useState } from 'react';

import { useWindowWidth } from '@virginexperiencedays/hooks';
import { newsLetterImage } from '@virginexperiencedays/cms/constants';
import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../layout/Image';
import { Button } from '../layout/Button';
import { BodyText } from '../typography/BodyText';
import { VisualHeading } from '../typography/VisualHeading';
import { DisplayHeading } from '../typography/DisplayHeading';

type BfNewsletterProps = {
  onSubmit: ({ email }: { email: string }, signUpSource: string) => void;
  className?: string;
  dataTestId?: string;
  title?: string;
  redTitle?: string;
  note?: string;
  onChange?: ({ email }) => void;
  signupSource?: string;
  priority?: boolean;
};
export const BfNewsletter = ({
  onSubmit,
  signupSource,
  priority,
  className,
  title = 'Sign up for all our latest & greatest',
  redTitle = 'exclusive deals.',
  note = 'By entering your email address you agree to receive emails from Virgin Experience Days about our latest experiences, news, and deals.',
  onChange,
}: BfNewsletterProps) => {
  const { width } = useWindowWidth();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const imageThemeEnv =
    process.env.NODE_ENV === 'production'
      ? newsLetterImage.production
      : newsLetterImage.development;
  const image = width > 768 ? imageThemeEnv.desktop : imageThemeEnv.mobile;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    onSubmit({ email }, signupSource ?? 'site');
    setIsSubmitted(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    onChange?.({ email });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        className={cn(
          'bg-background-page m-auto flex max-w-7xl flex-col-reverse',
          'md:flex-row md:items-center md:justify-between md:overflow-hidden md:p-0',
          'xl:h-[300px] xl:px-20 xl:py-10',
          className
        )}
        data-testid="bf-newsletter"
      >
        <div className="m-auto max-w-[500px] shrink-0 grow basis-1/2 md:text-center lg:ml-0 lg:text-left">
          {!isSubmitted ? (
            <div>
              <VisualHeading
                className="text-neutral-strong text-4xl !leading-tight -tracking-[0.02em] sm:text-4xl"
                as="h3"
              >
                {title} <span className="text-brand">{redTitle}</span>
              </VisualHeading>
              <div className="my-4 flex w-full flex-col items-center justify-center gap-4 md:flex-row md:gap-0">
                <input
                  className={cn(
                    'font-inter text-persistent-text-dark h-12 w-full appearance-none rounded-sm p-4 text-base leading-none',
                    'border-neutral-strong border',
                    'placeholder:text-neutral-faded',
                    'md:rounded-none md:rounded-l md:border-r-0 md:text-sm'
                  )}
                  onChange={handleChange}
                  placeholder="Your email address"
                  type="email"
                  value={email}
                />
                <Button
                  className={cn(
                    'h-[48px] shrink grow-0 basis-1/2 overflow-hidden text-ellipsis whitespace-nowrap whitespace-nowrap rounded border-2 border-transparent p-4 text-base leading-[14px]',
                    'md:min-w-[164px] md:basis-2/5 md:rounded-bl-none md:rounded-tl-none md:text-sm md:leading-[14px]'
                  )}
                  fullWidth
                  variant="primary"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Sign Up
                </Button>
              </div>
              <BodyText
                className="text-neutral-faded text-left md:text-center lg:text-left"
                size="small"
              >
                {note}
              </BodyText>
            </div>
          ) : (
            <div className="flex min-h-[249px] flex-col items-center justify-center text-white md:min-h-[186px]">
              <DisplayHeading className="text-neutral-strong mb-4" size="3">
                Thanks for signing up!
              </DisplayHeading>
              <BodyText className="text-neutral-faded" size="small">
                Look out for us in your inbox soon
              </BodyText>
            </div>
          )}
        </div>
        <div className="shrink-0 grow basis-1/2 text-center md:hidden lg:block lg:max-w-[560px]">
          <Image
            className="relative h-full pt-4 md:pt-0"
            src={image.src}
            alt="newsletter image"
            width={image.width}
            height={image.height}
            priority={priority}
          />
        </div>
      </div>
    </form>
  );
};
