import { MouseEvent } from 'react';

import { BodyText } from '../typography/BodyText';
import { DisplayHeading } from '../typography/DisplayHeading';
import { LinkButton, LinkButtonProps } from '../navigation/LinkButton';

const DEFAULT_TITLE_SIZE = '2';

type ChunkyMessageProps = {
  /**
   * Overtitle
   */
  overTitle?: string;

  /**
   * Title Line 1
   */
  titleLine1: string;

  /**
   * Title Line 2 - this merges with Title Line 1 as they will fall under one heading and will just be separated with `<br />`
   */
  titleLine2?: string;

  /**
   * Title heading level
   */
  titleSize?: '1' | '2' | '3' | '4' | '5' | '6';

  /**
   * Primary CTA Button
   */
  primaryCta?: {
    text?: string;
    href?: string;
  };

  /**
   * Secondary CTA Button
   */
  secondaryCta?: {
    text?: string;
    href?: string;
  };

  /**
   * Primary CTA click bindings (i.e. Tracking)
   */
  onPrimaryCtaClick?: (e: MouseEvent<HTMLElement>) => void;

  /**
   * Secondary CTA click bindings (i.e. Tracking)
   */
  onSecondaryCtaClick?: (e: MouseEvent<HTMLElement>) => void;
};

export const ChunkyMessage = ({
  overTitle,
  titleLine1,
  titleLine2,
  titleSize,
  primaryCta,
  secondaryCta,
  onPrimaryCtaClick,
  onSecondaryCtaClick,
}: ChunkyMessageProps) => {
  return (
    <section className="flex flex-col gap-6 md:flex-row md:items-end" data-testid="chunky-message">
      <div className="flex-1">
        {overTitle && (
          <BodyText
            as="span"
            className="text-brand overflow-wrap-anywhere mb-1 block font-semibold uppercase tracking-wider"
            data-testid="chunky-message-overtitle"
            size="small"
            leading="none"
          >
            {overTitle}
          </BodyText>
        )}
        <DisplayHeading
          className="text-neutral-strong overflow-wrap-anywhere text-3xl leading-tight md:text-4xl"
          data-testid="chunky-message-title"
          as={`h${titleSize || DEFAULT_TITLE_SIZE}`}
          size="1"
        >
          {titleLine1}
          {titleLine1 && titleLine2 && <br />}
          {titleLine2}
        </DisplayHeading>
      </div>
      {(primaryCta || secondaryCta) && (
        <div
          className="flex flex-wrap gap-2 md:flex-nowrap"
          data-testid="chunky-message-cta-container"
        >
          <CtaButton
            dataTestId="chunky-message-primary-cta"
            text={primaryCta?.text || 'Explore now'}
            href={primaryCta?.href}
            onClick={onPrimaryCtaClick}
            variant="primary"
          />
          <CtaButton
            dataTestId="chunky-message-secondary-cta"
            text={secondaryCta?.text || 'Learn more'}
            href={secondaryCta?.href}
            onClick={onSecondaryCtaClick}
            variant="outline"
          />
        </div>
      )}
    </section>
  );
};

const CtaButton = ({
  dataTestId,
  text,
  href,
  onClick,
  variant,
}: {
  dataTestId?: string;
  text: string;
  href: string;
  onClick?: any;
  variant?: LinkButtonProps['variant'];
  fullWidth?: boolean;
}) => {
  if (!href) return null;

  return (
    <LinkButton
      className="px-6"
      dataTestId={dataTestId}
      href={href}
      onClick={onClick}
      variant={variant}
      size="large"
      isExternal
    >
      {text}
    </LinkButton>
  );
};
