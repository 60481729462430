import { useCallback } from 'react';

import { Button as Component } from '@virginexperiencedays/components-v2/src/layout/Button';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import {
  getPrismicLinkPath,
  PrismicLink,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

import type { PrismicContext } from '../../types/context';

type SliceType = {
  slice: {
    primary: {
      content: string;
      url: PrismicLink;
      full_width?: boolean;
    };
    slice_type: string;
    variation: string;
  };
  context: PrismicContext;
};

const Button = ({ slice, context }: SliceType) => {
  const { primary, slice_type, variation } = slice;
  const { slug, hide, isFaceted, pageType, trackSlice } = context;

  const href = getPrismicLinkPath(primary?.url);

  const handleNavigate = useCallback(() => {
    trackSlice?.({
      slug: Array.isArray(slug) ? slug[slug.length - 1] : slug,
      label: primary.content,
      sliceType: slice_type,
      variation,
      pageType,
    });
    window.location.href = href;
  }, [href, slug, primary, slice_type, variation, pageType]);

  if (isFaceted || hide || !href || !primary?.content) return null;

  return (
    <Container className="flex justify-center">
      <Component
        className="hover:bg-background-primary focus:bg-background-primary h-12 bg-[#ff2f2f]"
        variant="primary"
        fullWidth={primary.full_width}
        onClick={handleNavigate}
      >
        {primary.content}
      </Component>
    </Container>
  );
};

export default Button;
