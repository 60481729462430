// Components
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import { BfNewsletter as Component } from '@virginexperiencedays/components-v2/src/BfNewsletter';
// Utils
import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
// Types
import type { PrismicContext } from '../../types/context';

type NewsletterSliceProps = {
  context: PrismicContext;
  slice: { primary: { sign_up_source: string }; position?: number };
};
const Newsletter = ({ context, slice }: NewsletterSliceProps) => {
  const { hide, isFaceted, onNewsletterSubmit } = context;
  const { primary, position } = slice;

  if (isFaceted || hide) {
    return null;
  }

  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <Component
        onSubmit={onNewsletterSubmit}
        signupSource={primary?.sign_up_source}
        priority={priority}
      />
    </Container>
  );
};

export default Newsletter;
