import { useAspectRatioSupported } from '@virginexperiencedays/hooks';
import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { BodyText } from '../../typography/BodyText';
import { DisplayHeading, DisplayHeadingProps } from '../../typography/DisplayHeading';
import {
  mapAspectRatio,
  mapAspectRatioPolyfill,
  ResponsiveAspectRatio,
} from '../../utils/mapAspectRatio';

export type CategoryCardProps = {
  /** Hero title used on center of the card (on top of image) */
  heroTitle: string;
  /** Title of the category */
  title: string;
  /** SEO override */
  titleAs?: DisplayHeadingProps['as'];
  /** Short description */
  description?: string;
  /** Image src to be displayed in the card */
  src: string;
  /** set the aspect ratio of the image */
  aspectRatio?: ResponsiveAspectRatio;
  /** Href of the card */
  href: string;
  /** Whether to force external link behavior for href */
  isHrefExternal?: boolean;
  /** Count displayed in top right corner of the card */
  count: number;
  className?: string;
  /** Whether to force priority image preloading for LCP benefit */
  priority: boolean;
  /** Tracking, etc */
  onClick?: (e: any) => void;
};

export const CategoryCard = ({
  className,
  heroTitle,
  title,
  titleAs,
  description,
  src,
  count,
  href,
  isHrefExternal,
  aspectRatio = '16/9',
  priority,
  onClick = () => {},
}: CategoryCardProps) => {
  const { isSupported } = useAspectRatioSupported();

  return (
    <LinkWrap
      href={href}
      isExternal={isHrefExternal}
      onClick={onClick}
      className={cn('group flex flex-col items-start gap-2 rounded no-underline', className)}
      dataTestId="category-card"
    >
      <div className="relative flex min-h-[200px] w-full overflow-hidden rounded">
        <Image
          className={cn(
            'image:object-cover absolute inset-0 h-full w-full transition-transform',
            'group-hover:scale-105',
            isSupported && mapAspectRatio(aspectRatio),
            !isSupported && mapAspectRatioPolyfill(aspectRatio)
          )}
          src={src}
          dataTestId="category-card-image"
          alt={title}
          priority={priority}
          fill
        />
        {count > 0 && (
          <div
            className="text-neutral-strong bg-background-page absolute right-2 top-2 rounded px-1 py-0.5 text-xs font-semibold"
            data-testid="category-card-count"
          >
            {count}
          </div>
        )}
        {heroTitle ? (
          <div className="absolute inset-0 z-[10] flex h-full w-full items-center justify-center overflow-hidden text-white">
            <DisplayHeading
              className="overflow-wrap-anywhere text-2xl font-bold leading-[1.2] text-white [text-shadow:rgba(40,_40,_40,_0.15)_0px_2px_4px,_rgba(0,_0,_0,_0.3)_0px_1px_1px]"
              data-testid="category-card-hero-title"
            >
              {heroTitle}
            </DisplayHeading>
          </div>
        ) : null}
      </div>
      <div className="relative mt-1 flex flex-col gap-1">
        <DisplayHeading
          className={cn(
            'overflow-wrap-anywhere mt-1 text-base font-bold leading-tight transition-colors',
            'group-hover:text-link',
            'text-neutral-strong'
          )}
          as={titleAs}
          data-testid="category-card-title"
        >
          {title}
        </DisplayHeading>
        {description ? (
          <BodyText
            className="text-neutral overflow-wrap-anywhere line-clamp-3 leading-normal"
            size="small"
            data-testid="category-card-description"
          >
            {description}
          </BodyText>
        ) : null}
      </div>
    </LinkWrap>
  );
};
