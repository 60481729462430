import { categoryPrefixes, categoryTypes } from '@virginexperiencedays/search/constants';

export function getCategoryPrefix({
  isOverride,
  categoryPrefix,
  categoryType,
}: {
  isOverride: boolean;
  categoryPrefix: categoryPrefixes;
  categoryType: categoryTypes;
}) {
  if (isOverride) {
    // There is only 2 statements due to only 2 types of overrides, it can be either occasion or category
    switch (categoryType) {
      case categoryTypes.OCCASION:
        return categoryPrefixes.OCCASION;

      case categoryTypes.CATEGORY:
        return categoryPrefixes.CATEGORY;
      default:
        throw new Error('Currently not supported category type for override');
    }
  }

  return categoryPrefix;
}
