import {
  type PrismicLink,
  getPrismicLinkPath,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

type NavigationItemType = {
  name: string;
  href?: string;
  link?:
    | (PrismicLink & { data?: { title?: string; display_title_override?: string } })
    | { link_type: 'Any' };
};

/**
 * - Format navigationItems to prioritize `link` over `href` (legacy)
 * - If a `name` value is not provided then fallback to `link.data.title` or `link.data.display_title_override`
 * - Do not return items which do not have any field name or valid href
 *
 * @param items - An array of NavigationItemType objects representing navigation items.
 * @returns An array of objects with the formatted name and href properties.
 */
export const formatNavigationItems = (
  items: NavigationItemType[]
): { name: string; href: string }[] => {
  if (!Array.isArray(items)) return [];

  const formattedItems: { name: string; href: string }[] = [];

  for (const item of items) {
    const name = getItemName(item);
    const href = getItemHref(item);

    if (name && href) formattedItems.push({ name, href });
  }

  return formattedItems;
};

/**
 *
 * @param item
 * @returns
 */
const getItemName = (item: NavigationItemType): string | null => {
  if (!item) return null;
  if (item.name) return item.name; // special case: document-type links have titles

  if (item.link?.link_type === 'Document') {
    return item.link.data?.display_title_override || item.link.data?.title;
  }

  // none of the above exist
  return null;
};

/**
 *
 * @param item
 * @returns
 */
const getItemHref = (item: NavigationItemType): string | null => {
  const hrefLink = item?.link;
  const legacyHref = item?.href;

  if (hrefLink?.link_type === 'Document') {
    return getPrismicLinkPath(hrefLink);
  }

  if (hrefLink?.link_type === 'Web' && typeof hrefLink?.url === 'string') {
    return hrefLink.url;
  }

  if (typeof legacyHref === 'string') {
    return legacyHref;
  }

  return null;
};
