import { useCallback, useMemo } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../../layout/Image';
import { LinkButton } from '../../navigation/LinkButton';
import { BodyText } from '../../typography/BodyText';
import { DisplayHeading } from '../../typography/DisplayHeading';

export type HeroStyle04CtaArrayProps = {
  id: number;
  label: string;
  link: string;
  anchorId?: string;
  onClick?: () => void;
};

export type HeroStyle04Props = {
  body?: string;
  className?: string;
  ctaArray?: HeroStyle04CtaArrayProps[];
  dataTestId?: string;
  imageAlt?: string;
  imageIsPriority?: boolean;
  imageUrl?: string;
  mobileImageAlt?: string;
  mobileImageUrl?: string;
  overTitle?: string;
  title?: string;
};

export const HeroStyle04 = ({
  body,
  className,
  ctaArray = [],
  dataTestId = 'hero-style-04',
  imageAlt,
  imageIsPriority,
  imageUrl,
  mobileImageAlt,
  mobileImageUrl,
  overTitle,
  title,
}: HeroStyle04Props) => {
  const filteredCtas = useMemo(() => {
    return ctaArray?.filter(({ link, anchorId }) => link || anchorId) ?? [];
  }, [ctaArray]);
  const getCtaButtonVariant = useCallback(
    (index: number) => {
      // use outline for all but the last CTA button
      return index >= filteredCtas.length - 1 ? 'primary' : 'outline';
    },
    [filteredCtas]
  );

  const imgAttr = {
    width: 767,
    height: 575,
    priority: imageIsPriority,
    imageClassName: 'object-cover object-center h-[200px] md:h-auto',
  };

  if (!imageUrl && !mobileImageUrl) return null;

  return (
    <div
      data-testid={dataTestId}
      className={cn(
        'text-neutral grid grid-cols-1 items-center gap-6 no-underline md:grid-cols-2 md:gap-10',
        className
      )}
    >
      <div className="overflow-hidden rounded-lg md:order-1 md:max-h-[unset] md:rounded-none md:[&_img]:rounded-lg">
        {!!mobileImageUrl && (
          <Image
            {...imgAttr}
            dataTestId="hero-style-04-image-mobile"
            className={cn(styles.image, 'block', imageUrl ? 'md:hidden' : 'md:block')}
            src={mobileImageUrl}
            alt={mobileImageAlt ?? imageAlt ?? title}
          />
        )}
        {!!imageUrl && (
          <Image
            {...imgAttr}
            dataTestId="hero-style-04-image-main"
            className={cn(styles.image, mobileImageUrl ? 'hidden' : 'block', 'md:block')}
            src={imageUrl}
            alt={imageAlt ?? title}
          />
        )}
      </div>
      <div className="p-0 text-center md:p-6 md:pl-0 md:text-left">
        <div className="flex-no-wrap mx-auto my-0 flex max-w-[480px] flex-col items-start gap-1 md:m-[unset] md:max-w-none md:gap-2">
          {!!overTitle && (
            <BodyText
              data-testid="hero-style-04-overtitle"
              as="span"
              size="extraSmall"
              leading="normal"
              className="bg-background-primary overflow-wrap-anywhere mx-auto my-0 block rounded px-1 py-0.5 font-semibold uppercase tracking-wider text-white md:m-0 md:text-base md:leading-tight"
            >
              {overTitle}
            </BodyText>
          )}
          {!!title && (
            <DisplayHeading
              data-testid="hero-style-04-title"
              size="2"
              className="text-neutral-strong text-balance text-[2rem] leading-tight md:text-5xl md:leading-none lg:text-[3.5rem] xl:text-[4rem]"
            >
              {title}
            </DisplayHeading>
          )}
          {!!body && (
            <BodyText
              data-testid="hero-style-04-body"
              className="m-0 text-balance md:text-xl md:leading-normal"
            >
              {body}
            </BodyText>
          )}
        </div>
        {!!filteredCtas?.length && (
          <div className="mx-auto my-4 mb-0 flex w-full max-w-[480px] gap-2 md:mx-0 md:my-10 md:mb-0 md:max-w-none md:flex-wrap">
            {filteredCtas.map(
              ({ id, label, link, anchorId, onClick }: HeroStyle04CtaArrayProps, i) => (
                <LinkButton
                  key={id}
                  className="flex-1 px-2 py-4 sm:px-6 md:flex-none"
                  dataTestId="hero-style-04-cta"
                  href={anchorId ? `#${anchorId}` : link}
                  onClick={onClick}
                  variant={getCtaButtonVariant(i)}
                  size="large"
                >
                  {label}
                </LinkButton>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  image:
    'relative h-[200px] [&>span]:max-h-[200px] [&>span]:w-full md:h-auto md:max-h-[unset] md:[&>span]:max-h-[unset]',
};
