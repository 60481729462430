import { useCallback } from 'react';
// Components
import { IconBanner } from '@virginexperiencedays/components-v2/src/banners/IconBanner';
// Utils
import { addToDataLayer } from '@virginexperiencedays/web/components/tracking/gtm/utils';
// Types
import type { PrismicContext } from '../../types/context';

type SliceProps = {
  slice: {
    primary: {
      title_1: string;
      title_2: string;
      body_1: string;
      body_2: string;
      url_1: any;
      url_2: any;
      style_1: 'Primary' | 'Grey100' | 'Grey900';
      style_2: 'Primary' | 'Grey100' | 'Grey900';
      icon_1: Icon;
      icon_2: Icon;
    };
  };
  context: PrismicContext;
};

type Icon = {
  url: string;
  alt?: string;
  Mobile?: {
    url: string;
    alt: string;
  };
};

const IconBanners = ({ slice, context }: SliceProps) => {
  const { primary } = slice;

  const { lastSlug, isFaceted } = context;

  const trackingOpts = {
    event: 'userInteraction',
    eventCategory: 'Category Page CMS',
    eventAction: `Clicked IconCard | ${lastSlug}`,
    eventLabel: primary?.title_1,
  };

  const clickTrackingOne = useCallback(
    () => addToDataLayer({ ...trackingOpts, eventLabel: primary?.title_1 }) as any,
    [lastSlug, primary?.title_1]
  );

  const clickTrackingTwo = useCallback(
    () => addToDataLayer({ ...trackingOpts, eventLabel: primary?.title_2 }) as any,
    [lastSlug, primary?.title_2]
  );

  if (isFaceted) return null;

  return (
    <section>
      <IconBanner
        bannerOne={{
          title: primary?.title_1,
          body: primary?.body_1,
          url: primary?.url_1,
          icon: primary?.icon_1,
          style: primary?.style_1,
          onClick: clickTrackingOne,
        }}
        bannerTwo={{
          title: primary?.title_2,
          body: primary?.body_2,
          url: primary?.url_2,
          icon: primary?.icon_2,
          style: primary?.style_2,
          onClick: clickTrackingTwo,
        }}
      />
    </section>
  );
};

export default IconBanners;
