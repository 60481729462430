import { cn } from '@virginexperiencedays/tailwind';
// Components
import { RichTextWrap } from '@virginexperiencedays/components-v2/src/typography/RichTextWrap';
import { StyledRichText } from '@virginexperiencedays/components-v2/src/typography/RichTextWrap/StyledRichText';

// Types
import * as prismicT from '@prismicio/types';

export type CMSRichTextProps = {
  textAlign?: 'left' | 'center' | 'right' | 'justify';
  scale?: string;
  maxWidth?: string;
  position?: string;
  content: prismicT.RichTextField;
};

export const CMSRichText = ({
  textAlign,
  scale,
  maxWidth,
  position,
  content,
}: CMSRichTextProps) => {
  return (
    <div
      data-testid="rich-text"
      className={cn(
        'cms-richtext',
        alignStyle(textAlign),
        scaleStyle(scale),
        postionStyle(position),
        'w-full max-w-none leading-normal'
      )}
      style={
        maxWidth
          ? {
              maxWidth: `${maxWidth}px`,
            }
          : {}
      }
    >
      <StyledRichText>
        <RichTextWrap field={content} />
      </StyledRichText>
    </div>
  );
};

const alignStyle = (align: CMSRichTextProps['textAlign']) => {
  switch (align) {
    case 'justify':
      return 'text-justify';
    case 'center':
      return 'text-center';
    case 'right':
      return 'text-right';
    case 'left': // falls through
    default:
      return 'text-left';
  }
};

const scaleStyle = (scale: string) => {
  switch (scale) {
    case 'large':
      return 'text-xl';
    case 'small':
      return 'text-sm';
    default:
      return 'text-base';
  }
};

const postionStyle = (position: string) => {
  switch (position) {
    case 'left':
      return 'mr-auto';
    case 'center':
      return 'mx-auto';
    case 'right':
      return 'ml-auto';
    default:
      return;
  }
};
