import { useCallback } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

type TabBarVariant = 'None' | 'Black Friday';

type TabBarProps = {
  tabs: {
    id: string;
    title: string;
  }[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  onTabClick?: (tabName: string) => void;
  variant?: TabBarVariant;
};

// Design Note:
// to achieve the TabBar bleeding on the side if there are still more items to scroll,
// "underline" div is in full width but the
// first tab's "before" content overlaps the left side while not yet scrolled, and
// "underline" div's "before" content overlaps the right side while also not yet scrolled
export const TabBar = ({ tabs, activeTab, setActiveTab, variant, onTabClick }: TabBarProps) => {
  const handleTabClick = useCallback(
    (index: number, title: string) => {
      setActiveTab(index);
      onTabClick?.(title);
    },
    [setActiveTab, onTabClick]
  );
  const mapTabItems = tabs.map(({ id, title }, index) => {
    return (
      <li
        key={`${id}-${title}`}
        className={cn(
          'relative cursor-pointer rounded-t-sm text-sm leading-none',
          getCnTitle(variant, activeTab === index)
        )}
      >
        <button
          className={cn('m-0 border-none bg-transparent p-4')}
          onClick={() => handleTabClick(index, title)}
          data-testid="tab-title"
        >
          {title}
        </button>
      </li>
    );
  });

  return (
    <div
      className={cn(
        'relative m-0 mb-6',
        /* tabs should overflow on the right on load, then when scrolling through, they should overflow on the left too */
        /* this will overlap the right underline to "hide" it when there are few tabs */
        variant !== 'Black Friday' &&
          'after:bg-background-page after:absolute after:-right-4 after:bottom-0 after:z-[1] after:h-px after:w-4 md:after:hidden'
      )}
      data-testid="tab-bar-wrapper"
    >
      <ul
        className={cn(
          'hide-scrollbar flex list-none overflow-x-auto whitespace-nowrap',
          '-mx-4 my-0 px-4 py-0 md:m-0 md:p-0',
          variant === 'Black Friday' && '-mx-4 px-4'
        )}
        data-testid="tab-bar"
      >
        {mapTabItems}
      </ul>
      {/* underline */}
      <div
        className={cn(
          'bg-border-neutral absolute -left-4 -right-4 bottom-0 z-0 h-px md:left-0 md:right-0',
          variant === 'Black Friday' && 'bg-background-primary'
        )}
      />
    </div>
  );
};

const getCnTitle = (variant: TabBarProps['variant'], isActive: boolean) => {
  switch (variant) {
    case 'Black Friday':
      return cn(
        'z-[1] rounded-t-sm border font-[500] md:[&>button]:px-6',
        isActive
          ? 'border-link border-b-background-page text-neutral-strong'
          : 'border-transparent border-b-link text-neutral',
        isActive &&
          'dark:bg-[linear-gradient(180deg,_rgba(225,_10,_10,_0.20)_40%,_rgba(225,_10,_10,_0)_100%)]'
      );

    default:
      return cn(
        'font-semibold z-[2]',
        isActive
          ? 'border-b-2 border-b-link text-link'
          : 'border-b-[1px] border-b-border-neutral text-neutral hover:bg-background-primary-faded',
        /* tabs should overflow on the right on load, then when scrolling through, they should overflow on the left too */
        /* this will overlap the left underline to "hide" it when it has not yet scrolled through */
        'first:before:absolute first:before:-left-4 first:before:z-[1] first:before:h-px first:before:w-4 first:before:bg-background-page md:first:before:hidden',
        isActive ? 'first:before:-bottom-0.5' : 'first:before:-bottom-px'
      );
  }
};
