import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { mapGap, Space } from '../../utils/mapSpace';

export type ColumnsProps = {
  children: Array<ReactNode> | ReactNode;
  className?: string;
  space?: Space;
  collapseBelow?: CollapseBelow;
  as?: 'ul' | 'div' | 'section';
  childAs?: 'li' | 'div';
  widths?: Width[];
} & HTMLAttributes<HTMLUListElement | HTMLDivElement>;

export const Columns = ({
  className,
  children,
  widths,
  collapseBelow = 'mobile',
  space = 'md',
  as = 'ul',
  childAs = 'li',
  ...rest
}: ColumnsProps) => {
  const Tag = as;
  const ChildTag = childAs;
  const isChildrenArray = Array.isArray(children);

  return (
    <Tag
      className={cn(
        'hide-scrollbar flex flex-row overflow-x-scroll',
        mapGap(space),
        mapCollapseBelow(collapseBelow),
        className
      )}
      data-testid="container-columns"
      {...rest}
    >
      {isChildrenArray ? (
        children.map((item, index) => {
          return (
            <ChildTag
              key={index}
              className={cn(
                'flex-1 list-none',
                widths?.[index] ? mapWidths(widths[index]) : 'w-full min-w-0'
              )}
            >
              {item}
            </ChildTag>
          );
        })
      ) : (
        <ChildTag className="w-full min-w-0 list-none">{children}</ChildTag>
      )}
    </Tag>
  );
};

type CollapseBelow = 'mobile' | 'tablet' | 'desktop' | 'wide';
function mapCollapseBelow(collapseBelow: CollapseBelow): string {
  switch (collapseBelow) {
    case 'mobile':
      return 'flex-col sm:flex-row';
    case 'tablet':
      return 'flex-col md:flex-row';
    case 'desktop':
      return 'flex-col lg:flex-row';
    case 'wide':
      return 'flex-col xl:flex-row';
    default:
      return 'flex-row';
  }
}

// We wanna keep it small as these classes will be included in the CSS bundle
// Also we are using predefined values due to JIT mode in tailwind disallowing us to dynamically generate classes
// This is only supporting its current usage to prevent the bundle from unnecessary bloat
type Width = 'max-content' | 'fit-content';
function mapWidths(width: Width): string {
  switch (width) {
    case 'max-content':
      return 'w-auto flex-none basis-[max-content]';
    case 'fit-content':
      return 'w-auto flex-none basis-[fit-content]';
  }
}
