import { ReactNode, useRef } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { Track, type TrackProps } from '../../layout/Carousel/Track';
import { CarouselHeading } from '../../layout/Carousel/Heading';
import { BodyText } from '../../typography/BodyText';
import { DisplayHeading } from '../../typography/DisplayHeading';
import { LinkButton } from '../../navigation/LinkButton';

type CarouselWithIntroProps = {
  className?: string;
  /**
   * Carousel elements
   */
  children: Array<ReactNode>;
  /**
   * Title displayed on the opposite site of the controls
   */
  title?: string;
  /**
   * Description shown below title
   */
  overTitle?: string;
  /**
   * Title for primary cta
   */
  primaryCtaTitle?: string;
  /**
   * Link for the primary CTA
   */
  primaryCtaLink?: string;
  /**
   * handle tracking and redirect
   */
  trackPrimaryCtaLink?: () => void;
  /**
   * Link for the CTA
   * When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle */
  href?: string;
  /**
   * If true, forces the CTA link to be an anchor tag to an external link, not
   * subject to Next.js client-side routing.
   */
  isHrefExternal?: boolean;
  /**
   * Title for cta
   */
  ctaTitle?: string;
  /**
   * Debounce time to prevent spamming, recommended is 350(default)
   */
  debounceTime?: number;
  /** When href is defined it replaces the CTA button with link , use href with ctaTitle, or onClick with ctaTitle*/
  onClick?: () => void;
  withHeading?: boolean;
  /**
   * Toggles displaying the navigation arrows.
   * True by default, for backward compatibility, but prefer this over the
   * negative hideNavigationArrows, which might cause confusion.
   */
  showNavigationArrows?: boolean;
};

export const CarouselWithIntro = ({
  className,
  children,
  title,
  overTitle,
  primaryCtaTitle,
  primaryCtaLink,
  trackPrimaryCtaLink,
  ctaTitle,
  href,
  isHrefExternal,
  debounceTime,
  onClick = null,
  withHeading = true,
  showNavigationArrows = true,
}: CarouselWithIntroProps) => {
  const carouselRef = useRef(null);
  const lastChildRef = useRef();
  const firstChildRef = useRef();

  const perPage = {
    tablet: 2,
    desktop: 3,
  } as TrackProps['perPage'];

  return (
    <div className={className}>
      {withHeading && (
        <CarouselHeading
          debounceTime={debounceTime}
          href={href}
          isHrefExternal={isHrefExternal}
          ctaTitle={ctaTitle}
          showNavigationArrows={showNavigationArrows}
          carouselRef={carouselRef}
          lastChildRef={lastChildRef}
          firstChildRef={firstChildRef}
          onClick={onClick}
        />
      )}

      <section className="flex flex-col gap-x-4 md:flex-row">
        <div
          className={cn(
            'flex flex-col justify-center',
            'md:w-[calc((100%_/_3)_-_10.66px)] lg:w-[calc((100%_/_4)_-_12px)]'
          )}
        >
          <div>
            <BodyText
              className="text-brand mb-1 font-semibold uppercase tracking-wider"
              size="small"
              leading="none"
            >
              {overTitle}
            </BodyText>
            <DisplayHeading className="mb-6 md:mb-0" as="h2" size="1">
              {title}
            </DisplayHeading>
            {primaryCtaLink && (
              <LinkButton
                className="hidden min-h-12 py-4 md:mt-6 md:inline-block"
                href={primaryCtaLink}
                onClick={trackPrimaryCtaLink}
                variant="primary"
              >
                {primaryCtaTitle}
              </LinkButton>
            )}
          </div>
        </div>

        <Track
          isProductCarousel
          carouselRef={carouselRef}
          firstChildRef={firstChildRef}
          lastChildRef={lastChildRef}
          perPage={perPage}
        >
          {children}
        </Track>
        {primaryCtaLink && (
          <LinkButton
            className="mt-10 block w-full md:hidden"
            variant="primary"
            size="large"
            href={primaryCtaLink}
            onClick={trackPrimaryCtaLink}
            fullWidth
          >
            {primaryCtaTitle}
          </LinkButton>
        )}
      </section>
    </div>
  );
};
