import { DEFAULT_IMAGE } from '../constants';
import { PrismicImage } from '../types';
import {
  PrismicLink,
  getPrismicLinkPath,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';

export const getContentLinkImage = (content_link: any, override?: PrismicImage) =>
  override?.url ??
  content_link?.data?.coverimage?.Thumbnail?.url ??
  content_link?.data?.og_image?.url ??
  content_link?.data?.legacy_og_image ??
  DEFAULT_IMAGE;

export const getContentLinkTitle = (content_link: any, override?: string) =>
  override ?? content_link?.data?.display_title_override ?? content_link?.data?.title;

// Empty Link field returns { "link_type": "Any" }
// Empty Content Link field returns { "link_type": "Document" } causing linkResolver error for missing type, uid
const isEmptyLink = (link?: any) =>
  !link ||
  link?.link_type === 'Any' ||
  (link?.link_type === 'Document' && !link?.type && !link?.uid);

const getPrismicLink = (link?: any) => (!isEmptyLink(link) ? getPrismicLinkPath(link) : null);

export const getContentLinkUrl = (content_link: any, override?: PrismicLink) =>
  getPrismicLink(override) ?? getPrismicLink(content_link) ?? '#';

// Strip off redundant "experience(s)" in the display text
export const stripExperiencesText = (str) =>
  str ? str.replace(/[eE]xperiences?$/, '').trim() : '';
