import { AccordionGroup as Component } from '@virginexperiencedays/components-v2/src/AccordionGroup';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';

import type { PrismicContext } from '../../types/context';

type FaqAccordionGroupProps = {
  slice: {
    primary: {
      title: string;
    };
    items: ItemType[];
  };
  context: PrismicContext;
};

type ItemType = { heading: string; content: []; is_open?: boolean };

const FaqAccordionGroup = ({ slice, context }: FaqAccordionGroupProps) => {
  const {
    primary: { title },
    items,
  } = slice;
  const { hide, isFaceted } = context;

  if (isFaceted || hide) {
    return null;
  }

  return (
    <Container>
      <Component title={title} items={items} />
    </Container>
  );
};

export default FaqAccordionGroup;
