import { limitValueTo } from '../../utils/limitValueTo';
import { type ColumnsType } from '../../utils/mapColumns';
import { Grid } from '../../layout/Grid';

import { Nugget } from '../Nugget';

export type MegaNuggetsProps = {
  /**
   * Defines the number of items per at certain breakopints
   */
  columns: ColumnsType;
  /**
   * The items rendered as nuggets
   */
  items?: {
    text: string;
    href: {
      id: string;
      slug: string;
      link: string;
      data?: { title?: string; title_override?: string };
    };
  }[];
  /**
   * Function to be called when a CTA nugget is clicked.
   */
  onClick?: (href: string, data?: any) => void;
};

const { max } = limitValueTo;

export const MegaNuggets = ({ columns, items = [], onClick }: MegaNuggetsProps) => {
  const { mobile, largeMobile, tablet, desktop, wide } = columns;
  const maxColumns = 8; // This should refer to column type biggest value (anyway the biggest currently used is 4, but extended this so it actually reuses code from the Grid)

  const breakpoints = {
    mobile: max(mobile, maxColumns),
    largeMobile: max(largeMobile, maxColumns),
    tablet: max(tablet, maxColumns),
    desktop: max(desktop, maxColumns),
    wide: max(wide, maxColumns),
  };

  if (!items?.length || !Array.isArray(items)) {
    return null;
  }

  return (
    <Grid
      elementTagAs="ul"
      space="md"
      dataTestId="mega-nuggets"
      columns={breakpoints as ColumnsType}
    >
      {items.map(({ text: name, href }) => {
        if (!href?.id || !href?.slug) return null;
        if (!name) return null; // this can occur in some cases and formatting the slug is not reliable

        const { id, link } = href;

        return (
          <li className="h-14 w-full" key={id}>
            <Nugget
              asLink
              className="text-neutral flex h-full w-full flex-row flex-wrap items-center justify-center rounded text-center leading-snug"
              href={link}
              onClick={() => onClick(link, { name })}
            >
              {name}
            </Nugget>
          </li>
        );
      })}
    </Grid>
  );
};
