import { SliceHeader } from '@virginexperiencedays/components-v2/src/SliceHeader';
import { Grid, type GridProps } from '@virginexperiencedays/components-v2/src/layout/Grid';
import { GridStructureType } from '../../types/slices';

type GridStructureWrapperProps = {
  children: React.ReactNode;
  primary: GridStructureType['primary'];
  columnsObj: GridProps['columns'];
  spaceOptions: GridProps['space'];
  spaceYOptions: GridProps['spaceY'];
  carouselCardSize: GridProps['carouselCardSize'];
};

const GridStructureWrapper = ({
  children,
  primary,
  columnsObj,
  spaceOptions,
  spaceYOptions,
  carouselCardSize,
}: GridStructureWrapperProps) => {
  return (
    <div>
      <SliceHeader
        title={primary.title}
        titleLvl={primary.title_level}
        description={primary.description}
      />
      <Grid
        columns={columnsObj}
        space={spaceOptions}
        spaceY={spaceYOptions}
        carouselCardSize={carouselCardSize}
      >
        {children}
      </Grid>
    </div>
  );
};

export default GridStructureWrapper;
