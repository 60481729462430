import { addToDataLayer } from '../components/tracking/gtm/utils';
import { hashEmail } from './hashEmail';

export const SIGNUP_SOURCE = {
  blogAuthor: 'blog-author',
  blogCategory: 'blog-category',
  blogPost: 'blog-post',
  ttdCounties: 'ttd-counties',
};

const signup = ({ email }: { email: string }, signupSource?: string): void => {
  fetch('/api/newsletter/signup', {
    method: 'POST',
    cache: 'no-cache',
    body: JSON.stringify({ email, signupSource: signupSource || 'site' }),
  });
};

export async function submit({ email }: { email: string }, signupSource?: string): Promise<void> {
  try {
    signup({ email }, signupSource);

    const emailHash = await hashEmail(email);

    if (emailHash) addToDataLayer({ event: 'newsletter_email_signup', emailHash });
  } catch (error) {
    console.warn('Failed to submit newsletter', error);
  }
}
