import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
import type { GridProps } from '@virginexperiencedays/components-v2/src/layout/Grid';

import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
import { carouselAspectRatio } from '../../utils/gridtoCarousel';
import { CardVariantsFactory, typeOptions } from '../../utils/CardVariantsFactory-v2';

import type { GridStructureType } from '../../types/slices';
import type { PrismicContext } from '../../types/context';

import GridStructureWrapper from './GridStructureWrapper';

const GridStructure = ({
  slice,
  context,
}: {
  slice: GridStructureType;
  context: PrismicContext;
}) => {
  const { primary, items, variation, position } = slice;
  const { hide, isFaceted, isProductCardsV2 } = context;

  const optionsObj = {
    variation,
    primary,
    items,
  };
  let carouselCardSize: 'small' | 'large';

  if (primary.carousel_on_mobile) {
    carouselCardSize =
      primary.card_type === 'basic_card' ||
      primary.card_type === 'category_card' ||
      primary.card_type === 'display_card'
        ? 'large'
        : 'small';
    if (!primary.mobile_aspect_ratio) {
      // over-rides aspect ratio if carousel_on_mobile is enabled
      optionsObj.primary.mobile_aspect_ratio = carouselAspectRatio(primary.card_type, variation);
    }
  }
  const defaultColumnsMobile = isProductCardsV2 ? 2 : 1;
  const mobileColumns = primary.mobile_number_of_columns
    ? parseInt(primary.mobile_number_of_columns, 10)
    : defaultColumnsMobile; // defaults if not defined

  const columnsObj = {
    mobile: mobileColumns,
    largeMobile: mobileColumns,
    tablet: parseInt(primary.tablet_number_of_columns, 10),
    desktop: parseInt(primary.desktop_number_of_columns, 10),
    wide: parseInt(primary.wide_number_of_columns, 10),
  };

  const titleClassName = isProductCardsV2 ? 'line-clamp-3' : '';

  const spaceOptions = {
    mobile: 'md',
  } as GridProps['space'];

  const spaceYOptions =
    primary.card_type === 'basic_card' ||
    primary.card_type === 'category_card' ||
    variation === 'cardsPostLinks'
      ? 'xl'
      : (null as GridProps['space']);

  if (isFaceted || hide) {
    return null;
  }

  // Determines whether images should be prioritised for LCP preloading
  const priority = position <= LCP_PREFETCH_SLICE_COUNT;

  return (
    <Container>
      <CardVariantsFactory
        Wrapper={GridStructureWrapper}
        wrapperProps={{
          primary,
          columnsObj,
          spaceOptions,
          spaceYOptions,
          carouselCardSize,
        }}
        options={typeOptions(optionsObj, variation)}
        priority={priority}
        isProductCardsV2={isProductCardsV2}
        isProductCardWithImageCarousel
        titleClassName={titleClassName}
      />
    </Container>
  );
};

export default GridStructure;
